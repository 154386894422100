import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import suppliersApi from "../api/suppliersApi";

export const fetchAllSuppliers = createAsyncThunk(
   "suppliers/fetchAllSuppliers",
   async () => {
      const result = await suppliersApi.apiGetAllSuppliers();
      return result;
   }
);

export const addSupplier = createAsyncThunk(
   "suppliers/addSupplier",
   async (supplier, { rejectWithValue }) => {
      try {
         const result = await suppliersApi.createSupplier(supplier);
         return result.data;
      } catch (error) {
         return rejectWithValue(error.response.data);
      }
   }
);

export const updateSupplier = createAsyncThunk(
   "suppliers/updateSupplier",
   async (supplier, { rejectWithValue }) => {
      try {
         const result = await suppliersApi.updateSupplier(
            supplier.id,
            supplier.values
         );
         return result.data;
      } catch (error) {
         return rejectWithValue(error.response.data);
      }
   }
);

const initialState = {
   suppliers: [],
   status: "idle",
   error: null,
};

const supplierSlice = createSlice({
   name: "suppliers",
   initialState,
   reducers: {
      createSupplier(state, action) {},
   },
   extraReducers(builder) {
      builder
         .addCase(fetchAllSuppliers.fulfilled, (state, action) => {
            state.status = "succeeded";

            state.suppliers = action.payload;
         })
         .addCase(addSupplier.fulfilled, (state, action) => {
            state.suppliers.push(action.payload);
         })
         .addCase(updateSupplier.fulfilled, (state, action) => {
            const index = state.suppliers.findIndex(
               (s) => s._id === action.payload._id
            );
            if (index !== -1) state.suppliers[index] = action.payload;
         });
   },
});

const { reducer, actions } = supplierSlice;
export const { createSupplier } = actions;
export default reducer;

export const selectAllSuppliers = (state) => state.suppliers.suppliers;
export const getSupplierStatus = (state) => state.suppliers.status;
