import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
   apiGetAllContacts,
   apiUpdateContact,
   apiCreateContact,
} from "../api/contactsApi";
import {
   updateShoeDb,
   addShoeDb,
   addShoeStock,
   reduceShoeStock,
} from "../api/shoesApi";

export const fetchAllContacts = createAsyncThunk(
   "contacts/fetchAllContacts",
   async () => {
      const result = await apiGetAllContacts();
      return result;
   }
);

export const addContact = createAsyncThunk(
   "contacts/addContact",
   async (contact, { rejectWithValue }) => {
      try {
         const result = await apiCreateContact(contact.values);
         return result.data;
      } catch (error) {
         return rejectWithValue(error.response.data);
      }
   }
);

export const updateContact = createAsyncThunk(
   "contacts/updateContact",

   async (contact, { rejectWithValue }) => {
      try {
         const result = await apiUpdateContact(contact.id, contact.values);
         return result.data;
      } catch (error) {
         return rejectWithValue(error.response.data);
      }
   }
);

export const updateShoe = createAsyncThunk(
   "contacts/updateShoe",
   async (shoe, { rejectWithValue }) => {
      try {
         const result = await updateShoeDb(shoe.id, shoe.values);
         return result.data;
      } catch (error) {
         return rejectWithValue(error.response.data);
      }
   }
);

export const addShoe = createAsyncThunk(
   "contacts/addShoe",
   async (shoe, { rejectWithValue }) => {
      try {
         const result = await addShoeDb(shoe.id, shoe.values);

         return result.data;
      } catch (error) {
         return rejectWithValue(error.response.data);
      }
   }
);

export const adjustShoeStock = createAsyncThunk(
   "contacts/adjustShoeStock",
   async (values, { rejectWithValue }) => {
      if (values.values.type === "in") {
         try {
            const result = await addShoeStock(
               values.id,
               values.values,
               values.contactId
            );
            return result.data;
         } catch (error) {
            return rejectWithValue(error.response.data);
         }
      } else {
         try {
            const result = await reduceShoeStock(
               values.id,
               values.values,
               values.contactId
            );
            return result.data;
         } catch (error) {
            return rejectWithValue(error.response.data);
         }
      }
   }
);

const initialState = {
   contacts: [],
   status: "idle",
   error: null,
};

const contactSlice = createSlice({
   name: "contacts",
   initialState,
   reducers: {
      createContact(state, action) {},
      deleteContact(state, action) {},
      createShoe(state, action) {},
      deleteShoe(state, action) {},
   },
   extraReducers(builder) {
      builder
         .addCase(fetchAllContacts.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.contacts = action.payload;
         })
         .addCase(fetchAllContacts.pending, (state, action) => {
            state.status = "loading";
         })
         .addCase(fetchAllContacts.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
         })
         .addCase(updateContact.fulfilled, (state, action) => {
            const index = state.contacts.findIndex(
               (contact) => action.payload._id === contact._id
            );

            if (index !== -1) state.contacts[index] = action.payload;
         })
         .addCase(addContact.fulfilled, (state, action) => {
            state.contacts.push(action.payload);
         })

         .addCase(updateShoe.rejected, (state, action) => {
            console.log(action.error.message);
         })
         .addCase(updateShoe.fulfilled, (state, action) => {
            const index = state.contacts.findIndex(
               (contact) => action.payload._id === contact._id
            );
            if (index !== -1) state.contacts[index] = action.payload;
         })
         .addCase(addShoe.rejected, (state, action) => {
            console.log(action.error.message);
         })
         .addCase(addShoe.fulfilled, (state, action) => {
            const index = state.contacts.findIndex(
               (contact) => action.payload._id === contact._id
            );
            if (index !== -1) state.contacts[index] = action.payload;
         })
         .addCase(adjustShoeStock.fulfilled, (state, action) => {
            const index = state.contacts.findIndex((contact) =>
               contact.shoes.some((shoe) => shoe._id === action.payload._id)
            );

            const shoeIndex = state.contacts[index].shoes.findIndex(
               (shoe) => shoe._id === action.payload._id
            );

            if (index !== -1 && shoeIndex !== -1)
               state.contacts[index].shoes[shoeIndex].amountOnStock =
                  action.payload.amountOnStock;
         });
   },
});
const { actions, reducer } = contactSlice;
export const { createContact, deleteContact, createShoe, deleteShoe } = actions;
export default reducer;

export const selectAllContacts = (state) => state.contacts.contacts;
export const getContactStatus = (state) => state.contacts.status;
export const selectContactById = (state, contactId) =>
   state.contacts.contacts.find((contact) => contact._id === contactId);
