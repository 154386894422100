import React from "react";

import Card from "react-bootstrap/Card";

import ContactForm from "../components/forms/ContactForm";
import ShoesComponent from "../components/ShoesComponent";

const ContactDetails = ({
  selectedContact,
  setSelectedContact,
  edit,
  onSubmit,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-12  col-lg-4">
          {selectedContact ? (
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <h4 className="card-title">Details</h4>
              </Card.Header>

              <Card.Body>
                {selectedContact ? (
                  <ContactForm
                    contact={selectedContact}
                    onSubmit={onSubmit}
                    edit={edit}
                  />
                ) : null}
              </Card.Body>
            </Card>
          ) : null}
        </div>
        <div className="col-12 col-lg-8">
          {selectedContact ? (
            <ShoesComponent
              contact={selectedContact}
              setSelectedContact={setSelectedContact}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ContactDetails;
