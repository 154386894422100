import React from "react";
import Table from "react-bootstrap/Table";
import { MdArrowCircleUp, MdArrowCircleDown } from "react-icons/md";

const ContactsTable = ({
   contacts,
   onClick,
   orderField,
   setOrderField,
   asc,
   setAsc,
}) => {
   const Icon = ({ field }) => {
      if (orderField === field) {
         if (asc) return <MdArrowCircleUp />;

         return <MdArrowCircleDown />;
      }

      return <MdArrowCircleUp />;
   };

   return (
      <Table striped hover responsive>
         <thead>
            <tr>
               <th
                  onClick={() => {
                     setOrderField("firstName");
                     setAsc(!asc);
                  }}
               >
                  First Name {<Icon field='firstName' />}
               </th>
               <th
                  onClick={() => {
                     setOrderField("lastName");
                     setAsc(!asc);
                  }}
               >
                  Last Name {<Icon field='lastName' />}
               </th>
               <th
                  onClick={() => {
                     setOrderField("email");
                     setAsc(!asc);
                  }}
               >
                  Email {<Icon field='email' />}
               </th>
               <th
                  onClick={() => {
                     setOrderField("type");
                     setAsc(!asc);
                  }}
               >
                  Type {<Icon field='type' />}
               </th>
            </tr>
         </thead>
         <tbody>
            {contacts?.map(contact => (
               <tr
                  key={contact._id}
                  onClick={() => onClick(contact)}
                  className='cursor-pointer'
               >
                  <td>{contact.firstName}</td>
                  <td>{contact.lastName}</td>
                  <td>{contact.email}</td>
                  <td> {contact.type}</td>
               </tr>
            ))}
         </tbody>
      </Table>
   );
};

export default ContactsTable;
