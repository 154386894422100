import React from "react";
import Pagination from "react-bootstrap/Pagination";

const PagePagination = ({
   pages,
   selectedPage,
   onClick,
   numberOfPages = 5,
}) => {
   let pageStart = 1;
   let pageEnd = pageStart + pages - 1;
   const Items = () => {
      const items = [];

      if (pages > numberOfPages) {
         pageStart = selectedPage;
         pageEnd =
            pageStart + numberOfPages - 1 > pages
               ? pages
               : pageStart + numberOfPages - 1;
      }

      for (let i = pageStart; i <= pageEnd; i++) {
         items.push(
            <Pagination.Item
               onClick={() => onClick(i)}
               key={i}
               active={selectedPage === i ? true : false}
            >
               {i}
            </Pagination.Item>
         );
      }
      return items;
   };
   return (
      <Pagination>
         <Pagination.Prev
            onClick={
               selectedPage > pageStart ? () => onClick(selectedPage - 1) : null
            }
         />
         {pages > numberOfPages && selectedPage !== 1 ? (
            <Pagination.Ellipsis />
         ) : null}

         <Items />

         {pages > numberOfPages &&
         selectedPage !== pages &&
         selectedPage !== pages - 1 ? (
            <Pagination.Ellipsis />
         ) : null}

         <Pagination.Next
            onClick={
               selectedPage !== pages ? () => onClick(selectedPage + 1) : null
            }
         />
      </Pagination>
   );
};

export default PagePagination;
