import React, { useState } from "react";
import * as yup from "yup";
import { Form } from "formik";
import Card from "react-bootstrap/Card";
import { Link, useNavigate } from "react-router-dom";

import AppForm from "./forms/AppForm";
import AppInputField from "./forms/AppInputField";
import Spinner from "../components/Spinner";
import useAuth from "../auth/useAuth";
import authApi from "../api/auth";
import images from "../constants/images";

const schema = yup.object().shape({
   email: yup.string().email().required().label("Username / Email"),
   password: yup.string().min(4).max(50).required().label("Password"),
});

const Login = () => {
   const { logIn } = useAuth();
   const navigate = useNavigate();
   const [error, setError] = useState(null);
   const [loading, setLoading] = useState(false);
   const initialValues = {
      email: "",
      password: "",
   };

   const handleSubmit = async (values) => {
      const { email, password } = values;
      if (error) setError(null);
      setLoading(true);
      const result = await authApi.apiLogin({ email, password });
      if (!result.success) {
         setError(result.error);
         setLoading(false);
         return;
      }
      setLoading(false);

      logIn(result.token);
      navigate("/");
   };
   return (
      <div className='wrapper'>
         <div className=' h-100  bg-primary d-flex flex-column justify-content-center align-items-center'>
            <Card>
               <Card.Header>
                  <img src={images.logo} alt='Logo' className='logo-image ' />

                  {loading ? <Spinner color='text-dark' /> : null}
               </Card.Header>
               <Card.Body>
                  <AppForm
                     initialValues={initialValues}
                     onSubmit={(values) => handleSubmit(values)}
                     validationSchema={schema}
                  >
                     <Form>
                        <AppInputField label='Username (email)' name='email' />
                        <AppInputField
                           label='Password'
                           name='password'
                           type='password'
                        />
                        <div className='text-danger'>
                           {error ? error : null}
                        </div>
                        <button
                           type='submit'
                           className='btn btn-outline-primary form-control mt-4'
                        >
                           Log in
                        </button>
                     </Form>
                  </AppForm>
                  <div className='mt-2 text-center'>
                     <Link to='/forgot-password'>
                        <span className='text-small'>Forgot password ?</span>
                     </Link>
                  </div>
               </Card.Body>
            </Card>
         </div>
      </div>
   );
};

export default Login;
