import React from "react";

import helperFunctions from "../utilities/helperFunctions";

const OrderDetails = ({ order }) => {
   return (
      <>
         <div className='row'>
            <div className='col-3 d-flex flex-column'>
               <div className='fw-bold'>Supplier</div>
               <div>{order?.supplier.supplierName}</div>
            </div>
            <div className='col-3 d-flex flex-column'>
               <div className='fw-bold'>Order date</div>
               <div>{helperFunctions.timestampToDate(order?.orderDate)}</div>
            </div>
            <div className='col-3 d-flex flex-column'>
               <div className='fw-bold'>Expected</div>
               <div>
                  {helperFunctions.timestampToDate(order?.expDeliveryDate)}
               </div>
            </div>
            <div className='col-3 d-flex flex-column'>
               <div className='fw-bold'>Amount</div>
               <div>{helperFunctions.sumOrderLines(order?.orderLines)}</div>
            </div>
         </div>
         <div className='pb-3'>
            <h5 className='mt-4'>Order Lines</h5>

            {order?.orderLines.map((line) => {
               const shoe = line.shoe;
               return (
                  <div key={line._id} className='row ms-3'>
                     <div className='col-4 mt-2'>
                        {`${line.contact.firstName} ${line.contact.lastName}`}
                     </div>
                     <div className='col-4 mt-2'>
                        {` ${shoe?.maker} ${shoe.size}  ${shoe.width} ${shoe.code} ${shoe.color}`}
                     </div>
                     <div className='col-4 mt-2'>{`Units: ${line.amount}`}</div>
                  </div>
               );
            })}
         </div>
      </>
   );
};

export default OrderDetails;
