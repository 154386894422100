import React from "react";
import constants from "../../constants/reportConstants";

const ReportButtons = ({
   onClick,
   selectedParam,
   labels = constants.monthLabels,
}) => {
   const buttons = [];

   labels.forEach((label, index) => {
      buttons.push(
         <button
            className={`btn btn-outline-primary btn-sm me-2 ${
               selectedParam === index ? "active" : ""
            }`}
            onClick={() => onClick(index)}
            key={index + label}
         >
            {label}
         </button>
      );
   });

   return buttons;
};

export default ReportButtons;
