import React, { useState } from "react";

import { Form, Field } from "formik";
import * as yup from "yup";
import AppForm from "./AppForm";
import AppInputField from "./AppInputField";
import AppSelectField from "./AppSelectField";

const schema = yup.object().shape({
   supplier: yup.string().required().label("Supplier Name"),
   maker: yup.string().max(50).label("Maker"),
   size: yup.string().required().max(25, "Max 25 characters"),
   width: yup.string().max(50).label("Width"),
   code: yup.string().max(50).label("Code"),
   vamp: yup.string().max(50).label("Vamp"),
   color: yup.string().max(50).label("Color"),
   sole: yup.string().max(50),
   insole: yup.string().max(50),
   amountOnStock: yup.number().required().default(0).label("Amount on stock"),
   otherSpecs: yup.string().max(255).label("Other specs"),
   price: yup.number().max(10000).label("Price"),
   status: yup.string().required().default("unused"),
});

const ShoeForm = ({ onSubmit, shoe = null, suppliers, status, onClick }) => {
   const [disabled, setDisabled] = useState(false);

   const options = suppliers.map((supplier) => {
      return { value: supplier._id, name: supplier.supplierName };
   });

   const initialValues = {
      supplier: shoe?.supplier._id || "",
      maker: shoe?.maker || "",
      size: shoe?.size || "",
      width: shoe?.width || "",
      code: shoe?.code || "",
      sole: shoe?.sole || "",
      vamp: shoe?.vamp || "",
      color: shoe?.color || "",
      status: shoe?.status || "active",
      insole: shoe?.insole || "",
      otherSpecs: shoe?.otherSpecs || "",
      price: shoe?.price || 0,
   };

   const handleChange = (event) => {
      if (event.target.name === "status")
         if (event.target.value !== "unused") setDisabled(false);
         else setDisabled(true);
   };

   useState(() => {
      if (shoe?.status === "unused") setDisabled(true);
   }, [shoe]);

   return (
      <AppForm
         initialValues={initialValues}
         onSubmit={(values) => onSubmit(values, shoe?._id)}
         validationSchema={schema}
         enableReinitialize={true}
         key={shoe?._id || "newShoe"}
      >
         <Form onChange={handleChange}>
            <div className='row mb-3'>
               <div className='col-12 col-md-6'>
                  <AppSelectField
                     label='Supplier'
                     name='supplier'
                     options={options}
                     disabled={disabled}
                  />
               </div>
               <div className='col-12 col-md-6'>
                  <AppInputField
                     label='Maker'
                     name='maker'
                     placeholder='Maker'
                     disabled={disabled}
                  />
               </div>
            </div>
            <div className='row mb-3'>
               <div className='col-12 col-md-6'>
                  <AppInputField
                     label='Size'
                     name='size'
                     placeholder='Size'
                     disabled={disabled}
                  />
               </div>
               <div className='col-12 col-md-6'>
                  <AppInputField
                     label='Width'
                     name='width'
                     placeholder='Width'
                     disabled={disabled}
                  />
               </div>
            </div>
            <div className='row mb-3'>
               <div className='col-12 col-md-6'>
                  <AppInputField
                     label='Code'
                     name='code'
                     placeholder='Code'
                     disabled={disabled}
                  />
               </div>
               <div className='col-12 col-md-6'>
                  <AppInputField
                     label='Vamp'
                     name='vamp'
                     placeholder='Vamp'
                     disabled={disabled}
                  />
               </div>
            </div>
            <div className='row mb-3'>
               <div className='col-12 col-md-6'>
                  <AppInputField
                     label='Color'
                     name='color'
                     placeholder='Color'
                     disabled={disabled}
                  />
               </div>
               <div className='col-12 col-md-6'>
                  <AppInputField
                     label='Sole'
                     name='sole'
                     placeholder='Sole'
                     disabled={disabled}
                  />
               </div>
            </div>
            <div className='row mb-3'>
               <div className='col-12 col-md-6'>
                  <AppInputField
                     label='Insole'
                     name='insole'
                     placeholder='Insole'
                     disabled={disabled}
                  />
               </div>
               <div className='col-12 col-md-6'>
                  <AppInputField
                     label='Price'
                     name='price'
                     placeholder='Price'
                     disabled={disabled}
                  />
               </div>
            </div>
            <div className='row'>
               {shoe ? (
                  <div className='col-12 col-md-6'>
                     <div className='row form-row'>
                        <label htmlFor='amountOnStock' className='form-label'>
                           Amount on Stock
                        </label>

                        <div className='col-6'>
                           <input
                              name='amountOnStock'
                              disabled
                              value={shoe?.amountOnStock}
                              className='form-control form-control-sm '
                           ></input>
                        </div>
                        <div className='col-6'>
                           <button
                              role='button'
                              className='btn btn-secondary btn-sm'
                              onClick={onClick}
                              type='button'
                           >
                              Adjust stock
                           </button>
                        </div>
                     </div>
                  </div>
               ) : null}
               <div className='col-12 col-md-6'>
                  <AppInputField
                     label='Other specs'
                     name='otherSpecs'
                     placeholder='Other specs'
                     component='textarea'
                     disabled={disabled}
                  />
               </div>
               <div className='col-12 col-md-6'>
                  <div>Status</div>
                  <div
                     className='form-check form-check-inline'
                     aria-labelledby='status-radio-group'
                  >
                     <Field
                        type='radio'
                        name='status'
                        value='active'
                        className='form-check-input'
                     />
                     <label className='form-check-label'>Active</label>
                  </div>
                  <div className='form-check form-check-inline'>
                     <Field
                        type='radio'
                        name='status'
                        value='trials'
                        className='form-check-input'
                     />
                     <label className='form-check-label'>Trials</label>
                  </div>
                  <div className='form-check form-check-inline'>
                     <Field
                        type='radio'
                        name='status'
                        value='unused'
                        className='form-check-input'
                     />
                     <label className='form-check-label'>Not in use</label>
                  </div>
               </div>
            </div>

            <div className='row mb-3 justify-content-center px-5'>
               <button
                  type='submit'
                  className='btn btn-primary mt-3'
                  disabled={status === "pending" ? true : false}
               >
                  {status === "pending" ? "Sending..." : ""}
                  {status !== "pending" && shoe ? "Update shoe" : "Add shoe"}
               </button>
            </div>
         </Form>
      </AppForm>
   );
};

export default ShoeForm;
