import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "react-bootstrap/Table";

import { selectAllContacts } from "../../redux/contactReducer";

import ReportButtons from "./ReportButtons";

import helpers from "../../utilities/helperFunctions";
import reportFunctions from "../../utilities/reportFunctions";
import stockApi from "../../api/stockApi";

const StockMovements = () => {
   const [stockMovements, setStockMovements] = useState([]);
   const [filteredStockMovements, setFilteredStockMovements] = useState([]);
   const [selectedMonth, setSelectedMonth] = useState(
      parseInt(new Date().getMonth())
   );
   const [selectedYear, setSelectedYear] = useState(
      new Date().getFullYear().toString()
   );
   const [selectedContactId, setSelectedContactId] = useState("all");
   const [years, setYears] = useState([]);
   const contacts = useSelector(selectAllContacts);

   const filteredContacts = contacts.filter(
      (contact) => contact.type === "dancer"
   );

   const getYears = (stockMovements) => {
      let years = [];
      stockMovements.forEach((element) => {
         if (!years.includes(element.createdAt.slice(0, 4)))
            years.push(element.createdAt.slice(0, 4));
      });

      years.sort((a, b) => a - b);
      setYears(years);
   };

   const getStockMovements = async () => {
      const result = await stockApi.fetchStockMovements();
      setStockMovements(result.data);
      getYears(result.data);
   };

   const handleMonthButtonClick = (monthIndex) => {
      setSelectedMonth(monthIndex);
   };

   const handleYearButtonClick = (yearIndex) => {
      setSelectedYear(years[yearIndex]);
   };
   const handleContactClick = (id) => {
      setSelectedContactId(id);
   };

   useEffect(() => {
      const filterStockMovements = (year, month, contactId) => {
         let filtered = [];

         if (month < 10) month = "0" + month;
         if (contactId === "all")
            filtered = stockMovements.filter(
               (s) =>
                  s.createdAt.slice(5, 7) === month &&
                  s.createdAt.slice(0, 4) === year
            );
         else
            filtered = stockMovements.filter(
               (s) =>
                  s.createdAt.slice(5, 7) === month &&
                  s.contact._id === contactId &&
                  s.createdAt.slice(0, 4) === year
            );
         return filtered;
      };

      setFilteredStockMovements(
         filterStockMovements(
            selectedYear,
            selectedMonth + 1,
            selectedContactId
         )
      );
   }, [selectedContactId, selectedMonth, selectedYear, stockMovements]);

   useEffect(() => {
      getStockMovements();
   }, []);

   return (
      <>
         <div className='mb-3'>
            <ReportButtons
               onClick={handleYearButtonClick}
               selectedParam={years.indexOf(selectedYear)}
               labels={years}
            />
         </div>
         <div className='mb-3'>
            {
               <ReportButtons
                  onClick={handleMonthButtonClick}
                  selectedParam={selectedMonth}
               />
            }
         </div>
         <div className='w-25 mb-3'>
            <select
               name='contacts'
               id='contacts'
               className='form-select '
               onChange={(e) => handleContactClick(e.target.value)}
            >
               <option value='all' key={0}>
                  All Dancers
               </option>
               {filteredContacts.map((contact) => (
                  <option
                     key={contact._id}
                     value={contact._id}
                  >{`${contact.firstName} ${contact.lastName}`}</option>
               ))}
            </select>
         </div>
         <div>
            <h5>
               Total stock movement out:{" "}
               {reportFunctions.sumStock(filteredStockMovements, "out")}
            </h5>
            <h5>
               Total stock movement in:{" "}
               {reportFunctions.sumStock(filteredStockMovements, "in")}
            </h5>
         </div>
         <Table striped={true}>
            <thead>
               <tr>
                  <th>Name</th>
                  <th>Shoe</th>
                  <th>Movement</th>
                  <th>Amount</th>
                  <th>Date</th>
               </tr>
            </thead>
            <tbody>
               {filteredStockMovements?.map((move) => {
                  const { supplier, maker, code, size, width } = move.shoe;
                  const { firstName, lastName } = move.contact;
                  return (
                     <tr key={move._id}>
                        <td>{`${firstName} ${lastName}`}</td>
                        <td>{`${supplier.supplierName} ${maker} ${code} ${size} ${width}`}</td>
                        <td>{move.type}</td>
                        <td>{move.amount}</td>
                        <td>{helpers.timestampToDate(move.createdAt)}</td>
                     </tr>
                  );
               })}
            </tbody>
         </Table>
      </>
   );
};

export default StockMovements;
