import React from "react";
import { Field, getIn } from "formik";

const FieldArrayErrorMessage = ({ name }) => (
   <Field name={name}>
      {({ form, field, meta }) => {
         const error = getIn(form.errors, name);
         const touch = getIn(form.touched, name);
         return touch && error ? (
            <span className='text-danger'>{error}</span>
         ) : null;
      }}
   </Field>
);

export default FieldArrayErrorMessage;
