import Card from "react-bootstrap/Card";

import React from "react";

export const DashboardCard = ({
   heading,
   total,
   Component = null,
   bg = {},
}) => {
   return (
      <Card className='rounded shadow border-color' bg={bg}>
         <Card.Body className='py-2'>
            <div className='d-flex flex-column justify-content-center align-items-center h-50'>
               <Card.Title className='flex w-100'>{heading}</Card.Title>
               <h4>{Component ? <Component /> : total}</h4>
            </div>
         </Card.Body>
      </Card>
   );
};
