import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { selectAllContacts, fetchAllContacts } from "../redux/contactReducer";
import { selectAllSuppliers } from "../redux/supplierReducer";
import { selectAllOrders, updateOrderStatus } from "../redux/ordersReducer";

import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";

import OrderForm from "../components/forms/OrderForm";
import OrderDetails from "../components/OrderDetails";
import OrdersTable from "../components/tables/OrdersTable";
import OrderRecievedForm from "../components/forms/OrderRecievedForm";
import Pagination from "../components/Pagination";
import helpers from "../utilities/helperFunctions";
import { receiveOrder } from "../api/ordersApi";
import { toast } from "react-toastify";

const Orders = () => {
   const contacts = useSelector(selectAllContacts);
   const suppliers = useSelector(selectAllSuppliers);
   const orders = useSelector(selectAllOrders);
   const dispatch = useDispatch();

   const [showModal, setShowModal] = useState(false);
   const [showDetailsModal, setShowDetailsModal] = useState(false);
   const [showRecievedModal, setShowReceivedModal] = useState(false);
   const [selectedOrder, setSelectedOrder] = useState();
   const [copyOrder, setCopyOrder] = useState();
   const [selectedPage, setSelectedPage] = useState(1);
   const [update, setUpdate] = useState(false);

   const handleCloseModal = () => {
      setCopyOrder(null);
      setUpdate(false);
      setShowModal(false);
   };
   const handleShowModal = () => {
      setShowModal(true);
   };

   const handleCopyClick = (order) => {
      setCopyOrder(order);
      setShowModal(true);
   };

   const handleModifyClick = (order) => {
      setCopyOrder(order);
      setUpdate(true);
      setShowModal(true);
   };

   const handleCloseDetailsModal = () => {
      setShowDetailsModal(false);
   };

   const handleOrderClick = (order) => {
      setSelectedOrder(order);
      setShowDetailsModal(true);
   };

   const handleRecievedClick = (order) => {
      setSelectedOrder(order);
      setShowReceivedModal(true);
   };

   const handleCloseRecievedModal = () => {
      setShowReceivedModal(false);
   };

   const handleReceivedSubmit = async (values) => {
      let ordersReceived = values.amountReceived.map((value) => {
         return {
            shoe: value.shoe._id,
            contact: value.contact._id,
            amount: value.amount,
            status: value.status,
         };
      });

      const orderLines = { orderLines: ordersReceived };
      console.log(orderLines);

      try {
         const result = await receiveOrder(orderLines, selectedOrder._id);
         if (!result.data.success) throw new Error(result.data.error);
         dispatch(fetchAllContacts());
         setShowReceivedModal(false);

         dispatch(
            updateOrderStatus({
               _id: selectedOrder._id,
               status: result.data.order.status,
               orderLines: result.data.order.orderLines,
            })
         );
         toast.success("Order added to stock");
      } catch (error) {
         toast.error(error.message);
         setShowReceivedModal(false);
      }
   };
   const filteredOrders = orders.filter((order) => order.status !== "received");

   const { pageItems, pages } = helpers.getPagination(
      filteredOrders,
      10,
      selectedPage
   );

   return (
      <div className='container-fluid'>
         <Card>
            <Card.Header className='d-flex  justify-content-between'>
               <h3>Orders</h3>
               <button className='btn btn-primary' onClick={handleShowModal}>
                  New order
               </button>
            </Card.Header>
            <Card.Body>
               <>
                  <OrdersTable
                     orders={pageItems}
                     onClick={handleOrderClick}
                     receivedClick={handleRecievedClick}
                     copyClick={handleCopyClick}
                     modifyClick={handleModifyClick}
                  />
                  {pages > 1 ? (
                     <div className='d-flex justify-content-center'>
                        <Pagination
                           pages={pages}
                           selectedPage={selectedPage}
                           onClick={(page) => setSelectedPage(page)}
                        />
                     </div>
                  ) : null}
               </>
            </Card.Body>
         </Card>

         <Modal show={showModal} size='lg' onHide={handleCloseModal}>
            <Modal.Header closeButton>
               <h2>{update ? "Update order" : "New order"}</h2>
            </Modal.Header>
            <Modal.Body>
               <OrderForm
                  contacts={contacts}
                  suppliers={suppliers}
                  onClick={handleCloseModal}
                  order={copyOrder}
                  update={update}
               />
            </Modal.Body>
         </Modal>
         <Modal
            show={showDetailsModal}
            onHide={handleCloseDetailsModal}
            size={"lg"}
         >
            <Modal.Header closeButton>
               <h2>Order Details</h2>
            </Modal.Header>
            <Modal.Body>
               <OrderDetails order={selectedOrder} />
            </Modal.Body>
         </Modal>
         <Modal
            show={showRecievedModal}
            onHide={handleCloseRecievedModal}
            size={"lg"}
         >
            <Modal.Header closeButton>
               <h2>Add order to stock</h2>
            </Modal.Header>
            <Modal.Body>
               <p>
                  Adjust units received to correct amount for orderlines and
                  then submit to add to stock.
               </p>
               <div className='row'>
                  <div className='col-3 d-flex flex-column'>
                     <div className='fw-bold'>Supplier</div>
                     <div>{selectedOrder?.supplier.supplierName}</div>
                  </div>
                  <div className='col-3 d-flex flex-column'>
                     <div className='fw-bold'>Order date</div>
                     <div>
                        {selectedOrder
                           ? helpers.timestampToDate(selectedOrder?.orderDate)
                           : null}
                     </div>
                  </div>
                  <div className='col-3 d-flex flex-column'>
                     <div className='fw-bold'>Expected delivery date</div>
                     <div>
                        {selectedOrder
                           ? helpers.timestampToDate(
                                selectedOrder?.expDeliveryDate
                             )
                           : null}
                     </div>
                  </div>
               </div>

               <hr />
               <OrderRecievedForm
                  order={selectedOrder}
                  onSubmit={handleReceivedSubmit}
               />
            </Modal.Body>
         </Modal>
      </div>
   );
};

export default Orders;
