import React from "react";
import { Link } from "react-router-dom";
import {
   MdDashboard,
   MdFilePresent,
   MdContacts,
   MdBusiness,
   MdReviews,
} from "react-icons/md";
import "./sidebar.scss";
import images from "../../constants/images";

const Sidebar = () => {
   return (
      <div className='sidebar'>
         <div className='sidebar-brand'>
            {<img src={images.logoWhite} alt='' />}
         </div>
         <nav>
            <Link to='/' className='navItem'>
               <MdDashboard className='icon' />
               <span>Dashboard</span>
            </Link>
            <Link to='/contacts' className='navItem'>
               <MdContacts className='icon' />
               <span>Contacts</span>
            </Link>

            <Link to='/orders' className='navItem'>
               <MdFilePresent className='icon' />
               <span>Orders</span>
            </Link>

            <Link to='/suppliers' className='navItem'>
               <MdBusiness className='icon' />
               <span>Suppliers</span>
            </Link>
            <Link to='/reports' className='navItem'>
               <MdReviews className='icon' />
               <span>Reports</span>
            </Link>
         </nav>
      </div>
   );
};

export default Sidebar;
