const sumStock = (stock, type) => {
   if (!stock.length) return 0;

   let sum = 0;
   stock.forEach((element) => {
      if (element.type === type) sum += element.amount;
   });

   return sum;
};

const stockMovmentsPerMonth = (stock, month) => {
   if (!stock) return;

   if (month < 10) month = "0" + month;

   const filteredStock = stock.filter((s) => s.createdAt.slice(5, 7) === month);
   return filteredStock;
};

const reportFunctions = {
   sumStock,
   stockMovmentsPerMonth,
};

export default reportFunctions;
