import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getOrders, createOrder, updateOrder } from "../api/ordersApi";

export const fetchOrders = createAsyncThunk(
   "orders/fetchOrders",

   async () => {
      const result = await getOrders();
      return result.data;
   }
);

export const addOrder = createAsyncThunk("orders/addOrder", async (order) => {
   const result = await createOrder(order);
   return result.data;
});

export const updateOrderDb = createAsyncThunk(
   "orders/updateOrderDb",
   async (payload, { rejectWithValue }) => {
      try {
         const result = await updateOrder(payload.id, payload.order);
         return result.data;
      } catch (error) {
         return rejectWithValue(error.response.data);
      }
   }
);

const initialState = {
   orders: [],
   status: "idle",
   error: null,
};

const orderSlice = createSlice({
   name: "orders",
   initialState,
   reducers: {
      updateOrderStatus(state, action) {
         const index = state.orders.findIndex(
            (order) => order._id === action.payload._id
         );
         if (index !== -1) {
            state.orders[index].status = action.payload.status;
            state.orders[index].orderLines = action.payload.orderLines;
         }
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchOrders.fulfilled, (state, action) => {
            state.orders = action.payload;
         })
         .addCase(addOrder.fulfilled, (state, action) => {
            state.orders.push(action.payload);
         })
         .addCase(updateOrderDb.fulfilled, (state, action) => {
            const index = state.orders.findIndex(
               (order) => order._id === action.payload._id
            );
            if (index !== -1) {
               state.orders[index] = action.payload;
            }
         });
   },
});

const { reducer, actions } = orderSlice;
export const { updateOrderStatus } = actions;
export default reducer;

export const selectAllOrders = (state) => state.orders.orders;
export const selectOrderStatus = (state) => state.orders.status;
