import React, { useEffect, useState } from "react";
import stockApi from "../../api/stockApi";
import constants from "../../constants/reportConstants";
import LineChart from "./LineChart";

const options = {
   responsive: true,
   scales: {},
   plugins: {
      legend: {
         position: "top",
      },
      title: {
         display: true,
         text: "Cost ",
      },
      subtitle: {
         display: true,
         text: "Total cost",
      },
   },
};

const labels = constants.monthLabels;

const data = {
   labels,
   datasets: [
      {
         label: "Purchase amount",
         data: [],
         borderColor: "rgb(9,9,121)",
         backgroundColor: "rgba(9,9,121, 0.5)",
      },
      {
         label: "Usage amount",
         data: [],
         borderColor: "rgb(255, 99, 132)",
         backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
   ],
};

const CostChartYear = ({ year = new Date(Date.now()).getFullYear() }) => {
   const [stock, setStock] = useState(null);
   const standardShoePrice = 550;

   const getStock = async () => {
      const result = await stockApi.fetchStockMovements(year);
      setStock(result.data);
   };

   const populateData = (stockData, type) => {
      const data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      stockData.forEach((movement) => {
         if (movement.type === type) {
            let month = new Date(movement.createdAt).getMonth();
            data[month] +=
               (movement.shoe.price || standardShoePrice) * movement.amount;
         }
      });
      return data;
   };

   const populateTotalCost = (stockData, type) => {
      const data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      stockData.forEach((movement) => {
         if (movement.type === type) {
            let month = new Date(movement.createdAt).getMonth();
            data[month] +=
               (movement.shoe.price || standardShoePrice) * movement.amount;
         }
      });
      return data;
   };

   if (stock) data.datasets[0].data = populateData(stock, "in");
   if (stock) data.datasets[1].data = populateData(stock, "out");

   useEffect(() => {
      if (!stock) getStock();
   }, [stock]);

   return <>{stock ? <LineChart options={options} data={data} /> : null}</>;
};

export default CostChartYear;
