import { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";

import apiAuth from "../api/auth";
import images from "../constants/images";

const PasswordReset = ({ reset }) => {
   const [password, setPassword] = useState("");
   const [email, setEmail] = useState("");
   const [searchParams] = useSearchParams();
   const navigate = useNavigate();

   const handleSubmit = async (e) => {
      e.preventDefault();

      if (!reset) {
         if (!email.trim().length > 4 && !email.trim().length < 50) {
            toast.error("Email must be between 4 and 20 characters");
            return;
         }

         const result = await apiAuth.apiForgotPassword({ email });
         if (!result.success) {
            toast.error(result.error);
            setEmail("");
         }

         toast.success(result.message);
         setEmail("");
      } else {
         if (!password.trim().length > 4 && !password.trim().length < 50) {
            toast.error("Password must be between 4 and 50 characters");
            return;
         }
         const queryParams = `?token=${searchParams.get(
            "token"
         )}&id=${searchParams.get("id")}`;
         const result = await apiAuth.apiResetPassword(queryParams, {
            password,
         });
         if (!result.success) {
            toast.error(result.error);
            setPassword("");
            return;
         }

         toast.success(result.message);
         setPassword("");
         navigate("/");
      }
   };

   const handleChange = (e) => {
      if (e.target.name === "email") setEmail(e.target.value);
      setPassword(e.target.value);
   };
   return (
      <div className='bg-primary h-100 d-flex justify-content-center align-items-center'>
         <Card>
            <Card.Header>
               <img src={images.logo} alt='Logo' className='logo-image ' />
            </Card.Header>
            {reset ? (
               <Card.Body>
                  <h5>Enter new password</h5>
                  <form onSubmit={handleSubmit}>
                     <div className='form-group'>
                        <label htmlFor='password'>New password</label>{" "}
                        <input
                           type='password'
                           name='password'
                           value={password}
                           onChange={handleChange}
                           className='form-control'
                           autoComplete='new-password'
                        />
                     </div>
                     <div className='form-group '>
                        <button
                           type='submit'
                           className='btn btn-primary mt-4 w-100'
                        >
                           Send
                        </button>
                     </div>
                  </form>
               </Card.Body>
            ) : (
               <Card.Body>
                  <h5>Enter email to reset password</h5>
                  <form onSubmit={handleSubmit}>
                     <div className='form-group'>
                        <label htmlFor='email'>Email</label>
                        <input
                           type='email'
                           name='email'
                           value={email}
                           onChange={handleChange}
                           className='form-control'
                        />
                     </div>
                     <div className='form-group '>
                        <button
                           type='submit'
                           className='btn btn-primary mt-4 w-100'
                        >
                           Send
                        </button>
                     </div>
                  </form>
               </Card.Body>
            )}
         </Card>
      </div>
   );
};

export default PasswordReset;
