import jwtDecode from "jwt-decode";

const getToken = () => {
   return localStorage.getItem("token");
};

const getUser = () => {
   const token = getToken();
   return token ? jwtDecode(token) : null;
};

const storeToken = (token) => {
   localStorage.setItem("token", token);
};

const removeToken = async () => {
   localStorage.removeItem("token");
};

const getTenant = () => {
   let tenant = "";
   const token = getToken();

   if (!token) return null;

   const user = jwtDecode(token);
   tenant = user.tenant;
   return tenant;
};

export default {
   getUser,
   removeToken,
   getToken,
   storeToken,
   getTenant,
};
