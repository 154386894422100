import React from "react";

import { Form } from "formik";
import * as yup from "yup";
import AppForm from "./AppForm";
import AppInputField from "./AppInputField";
import AppSelectField from "./AppSelectField";

const schema = yup.object({
   amount: yup.number().required().min(0).label("Amount"),
   type: yup.string().required().oneOf(["in", "out"]).label("Transaction type"),
});

const StockAdjustForm = ({ onSubmit, cancel }) => {
   const initialValues = {
      amount: "",
      type: "---Select type---",
   };

   const options = [
      {
         value: "in",
         name: "Stock in",
      },
      {
         value: "out",
         name: "Stock out",
      },
   ];
   return (
      <AppForm
         initialValues={initialValues}
         onSubmit={onSubmit}
         validationSchema={schema}
      >
         <Form>
            <div className='row mb-2'>
               <AppInputField label='Amount' name='amount' />
            </div>
            <div className='row mb-4'>
               <AppSelectField label='Type' name='type' options={options} />
            </div>

            <div className='row px-4'>
               <button type='submit' className='btn btn-primary'>
                  Adjust stock
               </button>
               <button
                  type='button'
                  className='btn btn-danger mt-3'
                  onClick={() => cancel()}
               >
                  Cancel
               </button>
            </div>
         </Form>
      </AppForm>
   );
};

export default StockAdjustForm;
