import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import Topbar from "./topbar/Topbar";

const Layout = ({ socket }) => {
   return (
      <>
         <Sidebar />

         <div className='main'>
            <Topbar />
            <Outlet />
         </div>
      </>
   );
};

export default Layout;
