const monthLabels = [
   "January",
   "February",
   "March",
   "April",
   "May",
   "June",
   "July",
   "August",
   "September",
   "October",
   "November",
   "December",
];

const reportContstants = {
   monthLabels,
};

export default reportContstants;
