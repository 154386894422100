import axios from "axios";
import { BASE_URL } from "./baseUrl";
import storage from "../auth/storage";

const URL = BASE_URL + "/suppliers";

const apiGetAllSuppliers = async () => {
   try {
      const result = await axios.get(URL, {
         headers: {
            "x-auth-token": storage.getToken(),
            tenant: storage.getTenant(),
         },
      });
      return result.data;
   } catch (error) {
      console.log(error.message);
      return error.message;
   }
};

const createSupplier = async (supplier) => {
   return await axios.post(URL, supplier.values, {
      headers: {
         "x-auth-token": storage.getToken(),
         tenant: storage.getTenant(),
      },
   });
};

const updateSupplier = async (id, supplier) => {
   return await axios.put(URL + `/${id}`, supplier, {
      headers: {
         "x-auth-token": storage.getToken(),
         tenant: storage.getTenant(),
      },
   });
};

const supplierApi = {
   apiGetAllSuppliers,
   createSupplier,
   updateSupplier,
};
export default supplierApi;
