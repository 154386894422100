const sumOrderLines = (orderLines) => {
   let sum = 0;
   for (let i = 0; i < orderLines.length; i++) {
      sum += orderLines[i].amount;
   }
   return sum;
};

const getShoe = (contactId, shoeId, contacts) => {
   const contact = contacts.filter((c) => c._id === contactId);
   const shoe = contact[0].shoes.filter((shoe) => shoe._id === shoeId);
   return shoe;
};

const timestampToDate = (time) => {
   return time.slice(0, 10).split("-").reverse().join("-");
};

const totalOnStock = (contacts, supplier = null) => {
   let total = 0;

   if (contacts.length) {
      contacts.forEach((contact) => {
         contact.shoes.forEach((shoe) => {
            if (supplier) {
               if (shoe.supplier._id === supplier) total += shoe.amountOnStock;
            } else total += shoe.amountOnStock;
         });
      });
   }
   return total;
};

const getPagination = (items = [], pagesize = 1, currentPage) => {
   let pages = Math.ceil(items.length / pagesize);
   const pageItems = items.slice(
      (currentPage - 1) * pagesize,
      currentPage * pagesize
   );

   return { pageItems, pages, pagesize };
};

const sortItems = (items, sortField, asc = true) => {
   //Sorts text items either ascending or descending
   let orderedItems = [];

   orderedItems = items.sort((a, b) => {
      const fieldA = a[sortField].toUpperCase();
      const fieldB = b[sortField].toUpperCase();
      if (fieldA < fieldB) return asc ? -1 : 1;
      if (fieldA > fieldB) return asc ? 1 : -1;
      return 0;
   });

   return orderedItems;
};

const isoToDate = (isoString) => {
   const date = new Date(isoString);
   const year = date.getFullYear();
   let month = date.getMonth() + 1;
   let day = date.getDate();

   if (day < 10) day = "0" + day;

   if (month < 10) month = "0" + month;

   return { day, month, year };
};

const helpers = {
   sumOrderLines,
   getShoe,
   timestampToDate,
   totalOnStock,
   getPagination,
   sortItems,
   isoToDate,
};

export default helpers;
