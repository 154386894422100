import React from "react";
import Table from "react-bootstrap/Table";

const SuppliersTable = ({ suppliers, onClick }) => {
   return (
      <Table striped hover responsive>
         <thead>
            <tr>
               <th>Supplier Name</th>
               <th>Email</th>
               <th>Phone</th>
               <th>Contact</th>
            </tr>
         </thead>
         <tbody>
            {suppliers?.map((supplier) => (
               <tr key={supplier._id} onClick={() => onClick(supplier)}>
                  <td>{supplier.supplierName}</td>
                  <td>{supplier.email}</td>
                  <td>{supplier.phone}</td>
                  <td>
                     {`${supplier.contact.firstName} ${supplier.contact.lastName}`}
                  </td>
               </tr>
            ))}
         </tbody>
      </Table>
   );
};

export default SuppliersTable;
