import axios from "axios";
import { BASE_URL } from "./baseUrl";
import storage from "../auth/storage";

const URL = BASE_URL + "/orders";

export const getOrders = async () => {
   return await axios.get(URL, {
      headers: {
         "x-auth-token": storage.getToken(),
         tenant: storage.getTenant(),
      },
   });
};

export const createOrder = async (order) => {
   return await axios.post(URL, order, {
      headers: {
         "x-auth-token": storage.getToken(),
         tenant: storage.getTenant(),
      },
   });
};
export const updateOrder = async (id, order) => {
   return await axios.put(URL + "/" + id, order, {
      headers: {
         "x-auth-token": storage.getToken(),
         tenant: storage.getTenant(),
      },
   });
};

export const receiveOrder = async (orderLines, orderId) => {
   return await axios.post(URL + "/received/" + orderId, orderLines, {
      headers: {
         "x-auth-token": storage.getToken(),
         tenant: storage.getTenant(),
      },
   });
};
