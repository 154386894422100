import React from "react";
import { Field, FieldArray, Form, Formik } from "formik";
import * as yup from "yup";

const schema = yup.object().shape({
   amountReceived: yup.array().of(
      yup.object().shape({
         amount: yup.number().required().min(0).label("Amount recieved"),
      })
   ),
});

const OrderRecievedForm = ({ order, onSubmit }) => {
   const initialValues = {
      amountReceived: order.orderLines || [{ amount: 0, shoe: "", status: "" }],
   };

   return (
      <div>
         <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, lines = order.orderLines }) => (
               <Form>
                  <FieldArray
                     name='amountReceived'
                     render={(arrayHelpers) => (
                        <div>
                           {values.amountReceived &&
                           values.amountReceived.length > 0
                              ? values.amountReceived.map((amount, index) => (
                                   <div key={index} className='row ms-3'>
                                      <div className='col-3 mt-2'>
                                         {`${lines[index].contact.firstName} ${lines[index].contact.lastName}`}
                                      </div>
                                      <div className='col-3 mt-2'>
                                         {` ${lines[index].shoe?.maker} ${lines[index].shoe.size}  ${lines[index].shoe.width} ${lines[index].shoe.code} ${lines[index].shoe.color}`}
                                      </div>
                                      <div className='col-2 mt-2'>{`Units: ${lines[index].amount}`}</div>
                                      <div className='col-2 mt-2'>
                                         <Field
                                            name={`amountReceived.[${index}].amount`}
                                            className='form-control'
                                            disabled={
                                               amount.status === "received"
                                                  ? true
                                                  : false
                                            }
                                         />
                                      </div>
                                      <div className='col-2 mt-3'>
                                         <label>
                                            <Field
                                               type='radio'
                                               name={`amountReceived.[${index}].status`}
                                               value='received'
                                               className='form-check-input'
                                               disabled={
                                                  amount.status === "received"
                                                     ? true
                                                     : false
                                               }
                                            />{" "}
                                            Received
                                         </label>
                                      </div>
                                   </div>
                                ))
                              : null}
                           <div>
                              <button
                                 type='submit'
                                 className='btn btn-primary mt-3 form-control'
                              >
                                 Submit
                              </button>
                           </div>
                        </div>
                     )}
                  />
               </Form>
            )}
         </Formik>
      </div>
   );
};

export default OrderRecievedForm;
