import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
   addSupplier,
   selectAllSuppliers,
   updateSupplier,
} from "../redux/supplierReducer";
import { selectAllContacts } from "../redux/contactReducer";

import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import AccordionCollapse from "react-bootstrap/esm/AccordionCollapse";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import SupplierForm from "../components/forms/SupplierForm";
import SuppliersTable from "../components/tables/SuppliersTable";
import Pagination from "../components/Pagination";

import helpers from "../utilities/helperFunctions";

const Suppliers = () => {
   const suppliers = useSelector(selectAllSuppliers);
   const contacts = useSelector(selectAllContacts);
   const [selectedSupplier, setSelectedSupplier] = useState(null);
   const [showModal, setShowModal] = useState(false);
   const [searchField, setSearchField] = useState("");
   const [selectedPage, setSelectedPage] = useState(1);
   const [edit, setEdit] = useState(true);

   const dispatch = useDispatch();

   const handleClick = (supplier) => {
      setSelectedSupplier(supplier);
      setEdit(true);
   };

   const handleShow = () => {
      setShowModal(true);
      setEdit(false);
   };
   const handleClose = () => {
      setShowModal(false);
      setEdit(true);
   };

   const handleSearchChange = (e) => {
      setSearchField(e.target.value);
   };

   const filteredSuppliers = suppliers?.filter((supplier) => {
      return supplier.supplierName
         .toLowerCase()
         .includes(searchField.toLowerCase());
   });

   const handleSubmit = async (values) => {
      if (edit) {
         try {
            await dispatch(
               updateSupplier({ id: selectedSupplier._id, values })
            ).unwrap();
            toast.success("Supplier updated!");
         } catch (error) {
            toast.error(error.error);
         }
      } else {
         try {
            const result = await dispatch(addSupplier({ values })).unwrap();
            if (result.error)
               throw new Error("Something went wrong adding supplier");

            toast.success("Supplier created!");
            handleClose();

            setSelectedSupplier(result);
         } catch (error) {
            toast.error(error.error);
         }
      }
   };

   useEffect(() => {
      setSelectedSupplier(suppliers?.[0]);
   }, []);

   const { pageItems, pages } = helpers.getPagination(
      filteredSuppliers,
      11,
      selectedPage
   );

   return (
      <main className='container-fluid'>
         <Accordion defaultActiveKey={["0"]} flush alwaysOpen>
            <Accordion.Item eventKey='0'>
               <Card className='shadow-sm'>
                  <AccordionHeader>
                     <h2>Suppliers</h2>
                  </AccordionHeader>
                  <AccordionCollapse eventKey='0'>
                     <Card.Body>
                        <div className='row g-5'>
                           <div className='col-12 col-lg-4 '>
                              {selectedSupplier ? (
                                 <SupplierForm
                                    supplier={selectedSupplier}
                                    contacts={contacts}
                                    edit={edit}
                                    onSubmit={handleSubmit}
                                 />
                              ) : null}
                           </div>
                           <div className='col-12 col-lg-8 '>
                              <div className='d-flex justify-content-between'>
                                 <form>
                                    <input
                                       type='text'
                                       name='search'
                                       id='search'
                                       className='form-control'
                                       onChange={handleSearchChange}
                                       placeholder='Search suppliers..'
                                    />
                                 </form>
                                 <button
                                    className='btn btn-outline-primary'
                                    onClick={handleShow}
                                 >
                                    Add Supplier
                                 </button>
                              </div>
                              <SuppliersTable
                                 suppliers={pageItems}
                                 onClick={handleClick}
                              />
                              {pages > 1 ? (
                                 <div className='d-flex justify-content-center'>
                                    <Pagination
                                       pages={pages}
                                       selectedPage={selectedPage}
                                       onClick={(page) => setSelectedPage(page)}
                                    />
                                 </div>
                              ) : null}
                           </div>
                        </div>
                     </Card.Body>
                  </AccordionCollapse>
               </Card>
            </Accordion.Item>
         </Accordion>
         <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
               <h4 className='card-title'>Create new supplier</h4>
            </Modal.Header>
            <Modal.Body>
               <SupplierForm
                  contacts={contacts}
                  edit={false}
                  onSubmit={handleSubmit}
               />
            </Modal.Body>
         </Modal>
      </main>
   );
};

export default Suppliers;
