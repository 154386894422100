import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import images from "../../constants/images";

const Menubar = () => {
   return (
      <Navbar bg='primary' data-bs-theme='dark'>
         <Container>
            <Navbar.Brand href='/'>
               <div className='w-6'>
                  <img src={images.logoWhite} width='80rem' alt='logo'></img>
               </div>
            </Navbar.Brand>

            <Nav className='justify-content-end'>
               <Nav.Link href='/login'>Log in</Nav.Link>
            </Nav>
         </Container>
      </Navbar>
   );
};

export default Menubar;
