import React from "react";
import { FieldArray, Form, Formik, Field } from "formik";
import * as yup from "yup";
import BsForm from "react-bootstrap/Form";
import { toast } from "react-toastify";

import { useDispatch } from "react-redux";
import { addOrder, updateOrderDb } from "../../redux/ordersReducer";

import AppSelectField from "./AppSelectField";
import DatePickerField from "./DatePickerField";
import FieldArrayErrorMessage from "./FieldArrayErrorMessage";

const schema = yup.object().shape({
   supplier: yup.string().min(1).required().label("Supplier"),
   orderDate: yup.date().required().label("Order date"),
   expDeliveryDate: yup.date().required().label("Expected delivery"),
   orderLines: yup.array().of(
      yup.object().shape({
         contact: yup.string().required().max(50).label("Contact"),
         shoe: yup.string().required().max(50).label("Shoe"),
         amount: yup
            .number()
            .required()
            .min(1, "Amount more than 1")
            .label("Amount"),
      })
   ),
});

const OrderForm = ({
   contacts,
   suppliers,
   onClick,
   order = null,
   update = false,
}) => {
   const dispatch = useDispatch();
   let orderLines;
   if (order) {
      orderLines = order?.orderLines.map((line) => {
         return {
            contact: line.contact._id,
            shoe: line.shoe._id,
            amount: line.amount,
            status: line.status,
         };
      });
   } else orderLines = false;

   const date = order && update ? new Date(order?.orderDate) : new Date();
   const initialValues = {
      supplier: (update && order.supplier._id) || "-- Select supplier --",
      orderDate: date,
      expDeliveryDate: "",
      orderLines: orderLines || [
         { contact: "", shoe: "", amount: 0, status: "ordered" },
      ],
   };

   const selectedContacts = contacts.filter((c) => c.type === "dancer");
   selectedContacts.sort(function (a, b) {
      const nameA = a.firstName.toUpperCase();
      const nameB = b.firstName.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
   });

   const getContactShoes = (id) => {
      const contactShoes = contacts.find((c) => c._id === id).shoes;
      return contactShoes;
   };

   const handleSubmit = async (values, { resetForm }) => {
      if (!update) {
         try {
            await dispatch(addOrder(values)).unwrap();
            toast.success("Order saved");
            resetForm();
            //Close modal
            onClick();
         } catch (error) {
            toast.error(error.message);
         }
      } else {
         try {
            await dispatch(
               updateOrderDb({ id: order._id, order: values })
            ).unwrap();
            //Close modal
            onClick();
            toast.success("Order updated");
         } catch (error) {
            toast.error(error.message);
         }
      }
   };

   return (
      <div className='container'>
         <div className='row'>
            <Formik
               initialValues={initialValues}
               onSubmit={handleSubmit}
               validationSchema={schema}
            >
               {({ values, setValues }) => (
                  <>
                     <Form>
                        <div className='row mb-3'>
                           <div className='col-4'>
                              <AppSelectField
                                 name='supplier'
                                 label='Supplier'
                                 options={suppliers}
                              />
                           </div>
                           <div className='col-4'>
                              <label className='form-label'>Order Date</label>
                              <DatePickerField
                                 name='orderDate'
                                 label='Order date'
                                 className='form-control'
                              />
                           </div>
                           <div className='col-4'>
                              <label className='form-label'>
                                 Expected delivery
                              </label>
                              <DatePickerField
                                 name='expDeliveryDate'
                                 label='Expected delivery'
                                 className='form-control'
                              />
                           </div>
                        </div>
                        <hr className='my-2' />
                        <FieldArray
                           name='orderLines'
                           render={(arrayHelpers) => (
                              <div>
                                 <h4>Order lines</h4>
                                 {values.orderLines.map((orderLine, index) => (
                                    <div key={index}>
                                       <div className='row mb-3'>
                                          <div className='col-3'>
                                             <BsForm.Group>
                                                <label
                                                   htmlFor={`orderLines[${index}].contact`}
                                                   className='form-label'
                                                >
                                                   Contact
                                                </label>
                                                <Field
                                                   name={`orderLines[${index}].contact`}
                                                   className='form-select'
                                                   as='select'
                                                   disabled={
                                                      orderLine.status ===
                                                         "received" && update
                                                         ? true
                                                         : false
                                                   }
                                                >
                                                   <option value='false'>
                                                      -- Select contact --
                                                   </option>
                                                   {selectedContacts?.map(
                                                      (contact) => (
                                                         <option
                                                            value={contact._id}
                                                            key={contact._id}
                                                         >
                                                            {`${contact.firstName} ${contact.lastName}`}
                                                         </option>
                                                      )
                                                   )}
                                                </Field>

                                                <FieldArrayErrorMessage
                                                   name={`orderLines[${index}].contact`}
                                                />
                                             </BsForm.Group>
                                          </div>

                                          <div className='col-3'>
                                             <BsForm.Group>
                                                <label
                                                   htmlFor={`orderLines[${index}].shoe`}
                                                   className='form-label'
                                                >
                                                   Shoe
                                                </label>
                                                <Field
                                                   name={`orderLines[${index}].shoe`}
                                                   className='form-select'
                                                   as='select'
                                                   disabled={
                                                      orderLine.status ===
                                                         "received" && update
                                                         ? true
                                                         : false
                                                   }
                                                >
                                                   <option value={null}>
                                                      -- Select shoe --
                                                   </option>
                                                   {values.orderLines[index]
                                                      .contact &&
                                                   values.orderLines[index]
                                                      .contact !== "false"
                                                      ? getContactShoes(
                                                           values.orderLines[
                                                              index
                                                           ].contact
                                                        ).map((shoe, index) => (
                                                           <option
                                                              value={shoe._id}
                                                              key={shoe._id}
                                                           >
                                                              {`${shoe.maker} ${shoe.code} ${shoe.size} ${shoe.width} ${shoe.color} `}
                                                           </option>
                                                        ))
                                                      : null}
                                                </Field>
                                                <FieldArrayErrorMessage
                                                   name={`orderLines[${index}].shoe`}
                                                />
                                             </BsForm.Group>
                                          </div>
                                          <div className='col-3'>
                                             <BsForm.Group>
                                                <label
                                                   htmlFor={`orderLines[${index}].amount`}
                                                   className='form-label'
                                                >
                                                   Amount
                                                </label>
                                                <Field
                                                   name={`orderLines[${index}].amount`}
                                                   className='form-control'
                                                   disabled={
                                                      orderLine.status ===
                                                         "received" && update
                                                         ? true
                                                         : false
                                                   }
                                                />
                                                <FieldArrayErrorMessage
                                                   name={`orderLines[${index}].amount`}
                                                />
                                             </BsForm.Group>
                                          </div>

                                          <div className='col-2 d-flex align-items-end'>
                                             {values.orderLines.length < 2 ||
                                             (orderLine.status === "received" &&
                                                update) ? null : (
                                                <button
                                                   className='btn btn-danger me-2'
                                                   onClick={() =>
                                                      arrayHelpers.remove(index)
                                                   }
                                                   type='button'
                                                >
                                                   -{" "}
                                                </button>
                                             )}
                                             {index ===
                                             values.orderLines.length - 1 ? (
                                                <button
                                                   type='button'
                                                   className='btn btn-secondary'
                                                   onClick={() =>
                                                      arrayHelpers.push({
                                                         shoe: "",
                                                         amount: 0,
                                                      })
                                                   }
                                                >
                                                   +
                                                </button>
                                             ) : null}
                                          </div>
                                       </div>
                                    </div>
                                 ))}
                              </div>
                           )}
                        />

                        <button type='submit' className='btn btn-primary'>
                           {update ? "Update order" : "Submit order"}
                        </button>
                     </Form>
                  </>
               )}
            </Formik>
         </div>
      </div>
   );
};

export default OrderForm;
