import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import socketIO from "socket.io-client";

import "./app.scss";
import "react-toastify/dist/ReactToastify.css";

import Login from "./components/Login";
import Layout from "./components/Layout";
import Contacts from "./containers/Contacts";
import Orders from "./containers/Orders";
import Suppliers from "./containers/Suppliers";
import Protected from "./auth/Protected";
import ErrorBoundary from "./components/ErrorBoundary";
import PasswordReset from "./containers/PasswordReset";
import Dashboard from "./containers/Dashboard";
import AuthContext from "./auth/context";
import Reports from "./components/Reports";
import storage from "./auth/storage";

import {
   fetchAllContacts,
   selectAllContacts,
   getContactStatus,
} from "./redux/contactReducer";
import { fetchAllSuppliers } from "./redux/supplierReducer";
import { fetchOrders } from "./redux/ordersReducer";
import { ToastContainer } from "react-toastify";
import Shoestocked from "./containers/Shoestocked";
import Contact from "./containers/Contact";

function App() {
   const dispatch = useDispatch();
   const contacts = useSelector(selectAllContacts);
   const contactStatus = useSelector(getContactStatus);

   const [user, setUser] = useState(null);
   const navigate = useNavigate();
   let socket = null;
   useEffect(() => {
      if (!user) {
         setUser(storage.getUser());
      }

      // if (user) {
      //    socket = socketIO.connect("http://localhost:9000", {
      //       auth: {
      //          tenant: user.tenant,
      //       },
      //    });
      //    socket.on("message", data => {
      //       console.log(data);
      //    });
      //    navigate("/");
      // }
   }, [user]);

   useEffect(() => {
      if (user) if (contactStatus === "idle") dispatch(fetchAllContacts());
   }, [user, contactStatus, dispatch]);

   useEffect(() => {
      if (user) dispatch(fetchAllSuppliers());
   }, [user, dispatch]);

   useEffect(() => {
      if (user) dispatch(fetchOrders());
   }, [user, dispatch]);

   return (
      <ErrorBoundary>
         <AuthContext.Provider value={{ user, setUser }}>
            <div className='wrapper'>
               <Routes>
                  <Route
                     path='/'
                     element={
                        <Protected user={user}>
                           <Layout socket={socket} />
                        </Protected>
                     }
                  >
                     <Route
                        path='/'
                        element={
                           <Dashboard status={contactStatus} socket={socket} />
                        }
                     />
                     <Route
                        path='/contacts'
                        element={<Contacts user={user} />}
                     />
                     <Route path='/orders' element={<Orders />} />
                     <Route path='/suppliers' element={<Suppliers />} />
                     <Route
                        path='/reports'
                        element={<Reports contacts={contacts} />}
                     />
                  </Route>
                  <Route path='/shoestocked' element={<Shoestocked />} />
                  <Route path='/login' element={<Login />} />
                  <Route path='/contact' element={<Contact />} />
                  <Route
                     path='/forgot-password'
                     element={<PasswordReset reset={false} />}
                  />
                  <Route
                     path='/reset-password'
                     element={<PasswordReset reset={true} />}
                  />
                  <Route path='*' element={<h1>Page not found: 404!</h1>} />
               </Routes>
            </div>
            <ToastContainer />
         </AuthContext.Provider>
      </ErrorBoundary>
   );
}

export default App;
