import React from "react";
import { Form } from "formik";
import * as yup from "yup";
import AppForm from "./AppForm";
import AppInputField from "./AppInputField";
import { sendFormEmail } from "../../api/sendEmail";
import { toast } from "react-toastify";

const schema = yup.object().shape({
   name: yup
      .string()
      .required()
      .min(2)
      .max(25, "Max 25 characters")
      .label("Name"),
   email: yup.string().email().required().max(50).label("Email"),
   message: yup.string(),
});

const ContactEmailForm = () => {
   const initialValues = {
      name: "",
      email: "",
      message: "",
   };
   const onSubmit = (values, helpers) => {
      try {
         sendFormEmail(values);
         helpers.resetForm();
         toast.success("Contact form sent!");
      } catch (error) {
         console.log(error);
         toast.error(error.message);
      }
   };

   return (
      <>
         <AppForm
            initialValues={initialValues}
            onSubmit={(values, helpers) => onSubmit(values, helpers)}
            validationSchema={schema}
            enableReinitialize={true}
            key={"messageForm"}
         >
            <Form>
               <div className='row mb-3'>
                  <div className='col-12 '>
                     <AppInputField
                        label='Name'
                        name='name'
                        placeholder='Name'
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-12 '>
                     <AppInputField
                        label='Email'
                        name='email'
                        placeholder='Email'
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-12 '>
                     <AppInputField
                        label='Message'
                        name='message'
                        placeholder='Message'
                        as='textarea'
                        rows={8}
                     />
                  </div>
               </div>

               <div className='row mb-3 justify-content-center px-5'>
                  <button type='submit' className='btn btn-primary mt-3'>
                     {"Send message"}
                  </button>
               </div>
            </Form>
         </AppForm>
      </>
   );
};

export default ContactEmailForm;
