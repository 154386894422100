import React from "react";
import Menubar from "../components/menubar/Menubar";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import ContactEmailForm from "../components/forms/ContactMailForm";

const Contact = () => {
   return (
      <div>
         <Menubar />
         <Container className='p-5'>
            <Row className='justify-content-center'>
               <Col xs={12} md={6}>
                  <div className=' '>
                     <ContactEmailForm />
                  </div>
               </Col>
            </Row>
         </Container>
      </div>
   );
};

export default Contact;
