import logo from "../assets/images/icon.png";
import logoWhite from "../assets/images/White-on-TransparentSmall.png";
import mobile1 from "../assets/images/mobile1.png";
import mobile2 from "../assets/images/mobile2.png";
import mobile3 from "../assets/images/mobile3.png";

const images = {
   logo,
   logoWhite,
   mobile1,
   mobile2,
   mobile3,
};
export default images;
