import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdAccountCircle, MdLogout } from "react-icons/md";
import useAuth from "../../auth/useAuth";

import "./topbar.scss";

const Topbar = () => {
   const { logOut, user } = useAuth();
   const navigate = useNavigate();

   const handleClick = () => {
      logOut();
      navigate("/login");
   };
   const [showMenu, setShowMenu] = useState(false);

   return (
      <div className='topbar d-flex justify-content-end'>
         <div className='user' onClick={() => setShowMenu(!showMenu)}>
            <span className='me-2'>{user?.name}</span>
            <MdAccountCircle className='icon' />
            <div className={showMenu ? "userMenu active" : "userMenu"}>
               <div onClick={handleClick}>
                  <MdLogout className='me-2' />
                  Log&nbsp;out
               </div>
            </div>
         </div>
      </div>
   );
};

export default Topbar;
