import axios from "axios";
import { BASE_URL } from "./baseUrl";
import storage from "../auth/storage";

export const apiGetContact = async (id) => {
   const result = await axios.get(BASE_URL + "/contacts/" + id, {
      headers: {
         "x-auth-token": storage.getToken(),
         tenant: storage.getTenant(),
      },
   });
   return result.data;
};

export const apiGetAllContacts = async () => {
   const result = await axios.get(BASE_URL + "/contacts", {
      headers: {
         "x-auth-token": storage.getToken(),
         tenant: storage.getTenant(),
      },
   });
   return result.data;
};

export const apiUpdateContact = async (id, contact) => {
   return await axios.put(BASE_URL + "/contacts/" + id, contact, {
      headers: {
         "x-auth-token": storage.getToken(),
         tenant: storage.getTenant(),
      },
   });
};

export const apiCreateContact = async (contact) => {
   return await axios.post(BASE_URL + "/contacts", contact, {
      headers: {
         "x-auth-token": storage.getToken(),
         tenant: storage.getTenant(),
      },
   });
};
