import Card from "react-bootstrap/Card";

function FeatureCard({
   title = "Title",
   subTitle = "Subtitle",
   text = "Some text",
   link = "",
   url = "Linkurl",
}) {
   return (
      <Card style={{ width: "18rem" }} className='h-100 shadow'>
         <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Subtitle className='mb-2 text-muted'>
               {subTitle}
            </Card.Subtitle>
            <div>{text}</div>
            {link ? <Card.Link href={url}>{link}</Card.Link> : null}
         </Card.Body>
      </Card>
   );
}

export default FeatureCard;
