import { configureStore } from "@reduxjs/toolkit";
import contactReducer from "./contactReducer";
import supplierReducer from "./supplierReducer";
import ordersReducer from "./ordersReducer";

const store = configureStore({
   reducer: {
      contacts: contactReducer,
      suppliers: supplierReducer,
      orders: ordersReducer,
   },
});

export default store;
