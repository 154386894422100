import React from "react";

import { Form, Field } from "formik";
import * as yup from "yup";
import AppForm from "./AppForm";
import AppInputField from "./AppInputField";
import AppSelectField from "./AppSelectField";

const schema = yup.object().shape({
   firstName: yup
      .string()
      .required()
      .min(2)
      .max(25, "Max 25 characters")
      .label("First name"),
   lastName: yup
      .string()
      .required()
      .min(2)
      .max(25, "Max 25 characters")
      .label("Last name"),
   email: yup.string().email().required().max(50).label("Email"),
   type: yup.string().required().label("Type"),
   admin: yup.boolean(),
   active: yup.boolean(),
});

const ContactForm = ({ contact = null, onSubmit, edit, status }) => {
   const initialValues = {
      firstName: contact?.firstName || "",
      lastName: contact?.lastName || "",
      email: contact?.email || "",
      type: contact?.type || "",
      admin: contact?.admin.toString() || "false",
      active: contact?.active?.toString() || "true",
   };

   const options = [
      { value: "dancer", name: "Dancer" },
      { value: "supplier", name: "Supplier" },
      { value: "user", name: "User" },
   ];

   return (
      <AppForm
         initialValues={initialValues}
         onSubmit={values => onSubmit(values, contact?._id)}
         validationSchema={schema}
         enableReinitialize={true}
         key={contact?._id || "newContact"}
      >
         <Form>
            <div className='row mb-3'>
               <div className='col-12 '>
                  <AppInputField
                     label='First name'
                     name='firstName'
                     placeholder='First name'
                  />
               </div>
            </div>
            <div className='row mb-3'>
               <div className='col-12 '>
                  <AppInputField
                     label='Last name'
                     name='lastName'
                     placeholder='Last name'
                  />
               </div>
            </div>
            <div className='row mb-3'>
               <div className='col-12 '>
                  <AppInputField
                     label='Email'
                     name='email'
                     placeholder='Email'
                  />
               </div>
            </div>
            <div className='row mb-3'>
               <div className='col-12 '>
                  <AppSelectField
                     label='Type'
                     name='type'
                     placeholder='Type'
                     options={options}
                  />
               </div>
            </div>
            <div className='row mb-3'>
               <div className='col-12 '>
                  <div id='my-radio-group'>Admin</div>
                  <div role='group' aria-labelledby='my-radio-group'>
                     <label className='me-2'>
                        <Field type='radio' name='admin' value='true' />
                        <span className='ps-1'>Yes</span>
                     </label>
                     <label className=''>
                        <Field type='radio' name='admin' value='false' />
                        <span className='ps-1'>No</span>
                     </label>
                  </div>
               </div>
            </div>
            <div className='row mb-3'>
               <div className='col-12 '>
                  <div id='my-active-radio-group'>Active</div>
                  <div role='group' aria-labelledby='my-active-radio-group'>
                     <label className='me-2'>
                        <Field type='radio' name='active' value='true' />
                        <span className='ps-1'>Yes</span>
                     </label>
                     <label className=''>
                        <Field type='radio' name='active' value='false' />
                        <span className='ps-1'>No</span>
                     </label>
                  </div>
               </div>
            </div>
            <div className='row mb-3 justify-content-center px-5'>
               <button
                  type='submit'
                  className='btn btn-primary mt-3'
                  disabled={status === "pending" ? true : false}
               >
                  {edit ? "Update Contact" : "Create contact"}
               </button>
            </div>
         </Form>
      </AppForm>
   );
};

export default ContactForm;
