import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { addContact, selectAllContacts } from "../redux/contactReducer";
import { updateContact } from "../redux/contactReducer";

import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import AccordionCollapse from "react-bootstrap/esm/AccordionCollapse";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import ContactDetails from "./ContactDetails";
import ContactsTable from "../components/tables/ContactsTable";
import ContactForm from "../components/forms/ContactForm";

import Pagination from "../components/Pagination";
import helpers from "../utilities/helperFunctions";

const Contacts = ({ user }) => {
   const contacts = useSelector(selectAllContacts);
   const [selectedContact, setSelectedContact] = useState(null);
   const [showModal, setShowModal] = useState(false);
   const [searchField, setSearchField] = useState("");
   const [orderField, setOrderField] = useState("firstName");
   const [selectedPage, setSelectedPage] = useState(1);
   const [edit, setEdit] = useState(true);
   const [asc, setAsc] = useState(true);
   const [showInactive, setShowInactive] = useState(false);
   const [activeKeys, setActiveKeys] = useState(["1"]);

   const dispatch = useDispatch();

   const handleClick = contact => {
      const index = contacts.findIndex(c => c._id === contact._id);
      setSelectedContact(contacts[index]);
      setActiveKeys(["0", "1"]);
   };
   const handleShow = () => {
      setEdit(false);
      setShowModal(true);
   };
   const handleClose = () => {
      setEdit(true);
      setShowModal(false);
   };

   const handleSearchChange = e => {
      setSearchField(e.target.value);
   };

   const handleSubmit = async (values, id) => {
      if (edit) {
         try {
            await dispatch(updateContact({ id, values })).unwrap();
            toast.success("Contact updated");
         } catch (error) {
            toast.error(error.message);
            return;
         }
      } else {
         values["password"] = "Test12345";
         values["tenant"] = user.tenant;

         try {
            await dispatch(addContact({ values })).unwrap();
            handleClose();
            toast.success("Contact created!");
         } catch (error) {
            toast.error(error.error);
         }
      }
   };

   useEffect(() => {
      const updateSelected = () => {
         if (selectedContact) {
            const index = contacts.findIndex(
               c => c._id === selectedContact._id
            );
            setSelectedContact(contacts[index]);
         }
      };

      updateSelected();
   }, [contacts]);
   useEffect(() => {}, [edit]);

   const sortedContacts = helpers.sortItems([...contacts], orderField, asc);

   const filteredContacts = sortedContacts?.filter(contact => {
      return (
         contact?.firstName.toLowerCase().includes(searchField.toLowerCase()) ||
         contact?.lastName.toLowerCase().includes(searchField.toLowerCase())
      );
   });

   const handleSelect = eventKey => setActiveKeys(eventKey);

   const { pageItems, pages } = helpers.getPagination(
      filteredContacts.filter(contact => {
         if (showInactive) return contact;
         if (contact.active) return contact;
      }),
      10,
      selectedPage
   );
   return (
      <main className='container-fluid'>
         <Accordion
            activeKey={activeKeys}
            flush
            alwaysOpen
            onSelect={handleSelect}
         >
            <Accordion.Item eventKey='0'>
               <Card className='shadow-sm'>
                  <AccordionHeader>
                     <h2>Contact details</h2>
                  </AccordionHeader>
                  <AccordionCollapse eventKey='0'>
                     <Card.Body>
                        <ContactDetails
                           contacts={contacts}
                           edit={edit}
                           selectedContact={selectedContact}
                           setSelectedContact={setSelectedContact}
                           onSubmit={handleSubmit}
                        />
                     </Card.Body>
                  </AccordionCollapse>
               </Card>
            </Accordion.Item>
            <Accordion.Item eventKey='1'>
               <Card className='shadow-sm'>
                  <AccordionHeader>
                     <h2>Contacts</h2>
                  </AccordionHeader>
                  <AccordionCollapse eventKey='1'>
                     <Card.Body>
                        <div className='d-flex justify-content-between'>
                           <form className='d-flex align-items-center gap-3'>
                              <input
                                 type='text'
                                 name='search'
                                 id='search'
                                 className='form-control'
                                 onChange={handleSearchChange}
                                 placeholder='Search contacts..'
                              />
                              <div className='form-check form-switch'>
                                 <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='active'
                                    checked={showInactive}
                                    onChange={() =>
                                       setShowInactive(!showInactive)
                                    }
                                 />
                                 <label
                                    className='form-check-label'
                                    htmlFor='active'
                                 >
                                    Show&nbsp;inactive
                                 </label>
                              </div>
                           </form>

                           <button
                              className='btn btn-outline-primary'
                              onClick={handleShow}
                           >
                              Add Contact
                           </button>
                        </div>
                        <ContactsTable
                           contacts={pageItems}
                           onClick={handleClick}
                           setOrderField={setOrderField}
                           asc={asc}
                           setAsc={setAsc}
                           orderField={orderField}
                        />
                        {pages > 1 ? (
                           <div className='d-flex justify-content-center'>
                              <Pagination
                                 pages={pages}
                                 selectedPage={selectedPage}
                                 onClick={page => setSelectedPage(page)}
                              />
                           </div>
                        ) : null}
                     </Card.Body>
                  </AccordionCollapse>
               </Card>
            </Accordion.Item>
         </Accordion>
         <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
               <h4 className='card-title'>Create new contact</h4>
            </Modal.Header>
            <Modal.Body>
               <ContactForm
                  contact={null}
                  onSubmit={handleSubmit}
                  edit={edit}
               />
            </Modal.Body>
         </Modal>
      </main>
   );
};

export default Contacts;
