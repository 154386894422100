import React from "react";
import { Field, useFormikContext, ErrorMessage } from "formik";
import Form from "react-bootstrap/Form";

const AppSelectField = ({ label, name, options, ...otherProps }) => {
   const { values, touched } = useFormikContext();
   return (
      <Form.Group>
         <label htmlFor={name} className='form-label fs-sm'>
            {label}
         </label>
         <Field
            id={name}
            name={name}
            as='select'
            {...otherProps}
            className='form-select form-select-sm'
            value={values[name]}
         >
            <option value={null}>{`---Select ${label}---`}</option>
            {options.map((option) => (
               <option
                  value={option.value || option._id}
                  key={option.value || option._id}
               >
                  {option.name || option.supplierName}
               </option>
            ))}
         </Field>
         <span className='text-danger'>
            {touched[name] ? <ErrorMessage name={name} /> : null}
         </span>
      </Form.Group>
   );
};

export default AppSelectField;
