import React from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { adjustShoeStock } from "../../redux/contactReducer";

import StockAdjustForm from "../forms/StockAdjustForm";

const AdjustStockModal = ({
   onStockClose,
   showStockModal,
   shoe,
   contactId,
}) => {
   const dispatch = useDispatch();

   const handleStockChange = async (values) => {
      try {
         await dispatch(
            adjustShoeStock({
               id: shoe._id,
               values,
               contactId,
            })
         ).unwrap();
         toast.success("Stock updated");
         onStockClose();
      } catch (error) {
         toast.error("Something went wrong: " + error.error);
      }
   };

   return (
      <Modal show={showStockModal} onHide={onStockClose} size={"sm"}>
         <Modal.Header closeButton>
            <Modal.Title>Update Stock</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <h6>{`${shoe?.supplier.supplierName} ${shoe?.maker}  ${shoe?.code} ${shoe?.size} ${shoe?.width}`}</h6>
            <div className='row mb-3 px-2'>
               <StockAdjustForm
                  onSubmit={handleStockChange}
                  cancel={onStockClose}
               />
            </div>
         </Modal.Body>
      </Modal>
   );
};

export default AdjustStockModal;
