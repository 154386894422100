import React, { useEffect, useState } from "react";
import stockApi from "../../api/stockApi";
import constants from "../../constants/reportConstants";
import LineChart from "./LineChart";

const options = {
   responsive: true,
   scales: {},
   plugins: {
      legend: {
         position: "top",
      },
      title: {
         display: true,
         text: "Shoes ",
      },
   },
};

const labels = constants.monthLabels;

const data = {
   labels,
   datasets: [
      {
         label: "Shoes In",
         data: [],
         borderColor: "rgb(9,9,121)",
         backgroundColor: "rgba(9,9,121, 0.5)",
      },
      {
         label: "Shoes Out",
         data: [],
         borderColor: "rgb(255, 99, 132)",
         backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
   ],
};

const StockChartYear = ({ year = new Date(Date.now()).getFullYear() }) => {
   const [stockOut, setStockOut] = useState(null);
   const [stockIn, setStockIn] = useState(null);

   const getStockOut = async () => {
      const result = await stockApi.getStockMovementsPerMonth(year, "out");
      setStockOut(result.data);
   };
   const getStockIn = async () => {
      const result = await stockApi.getStockMovementsPerMonth(year, "in");
      setStockIn(result.data);
   };

   const populateData = (stockData) => {
      const data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      stockData.forEach((month) => {
         data[month._id - 1] = month.total;
      });
      return data;
   };

   if (stockOut) data.datasets[1].data = populateData(stockOut);
   if (stockIn) data.datasets[0].data = populateData(stockIn);

   useEffect(() => {
      if (!stockIn) getStockIn();
      if (!stockOut) getStockOut();
   }, [stockIn, stockOut]);

   return (
      <>
         {stockIn && stockOut ? (
            <LineChart options={options} data={data} />
         ) : null}
      </>
   );
};

export default StockChartYear;
