import React from "react";

import Table from "react-bootstrap/Table";
import helperFunctions from "../../utilities/helperFunctions";

const OrdersTable = ({
   orders,
   onClick = null,
   receivedClick = null,
   copyClick = null,
   modifyClick = null,
}) => {
   return (
      <Table striped responsive>
         <thead>
            <tr>
               <th>Supplier</th>
               <th>Order date</th>
               <th>Expected date</th>
               <th>Amount</th>
               <th>Status</th>
               <th></th>
            </tr>
         </thead>
         <tbody>
            {orders?.length > 0
               ? orders?.map((order) => (
                    <tr key={order._id} className='cursor-pointer'>
                       <td>{order.supplier.supplierName}</td>
                       <td>
                          {helperFunctions.timestampToDate(order.orderDate)}
                       </td>
                       <td>
                          {helperFunctions.timestampToDate(
                             order.expDeliveryDate
                          )}
                       </td>
                       <td>
                          {helperFunctions.sumOrderLines(order.orderLines)}
                       </td>
                       <td>{order.status}</td>
                       <td>
                          {onClick ? (
                             <>
                                <button
                                   className='btn btn-sm btn-outline-danger me-3'
                                   onClick={() => modifyClick(order)}
                                >
                                   Modify
                                </button>
                                <button
                                   className='btn btn-sm btn-outline-secondary me-3'
                                   onClick={() => onClick(order)}
                                >
                                   View
                                </button>

                                <button
                                   className='btn btn-sm btn-outline-primary me-3'
                                   onClick={() => receivedClick(order)}
                                >
                                   Recieve
                                </button>
                                <button
                                   className='btn btn-sm btn-outline-info'
                                   onClick={() => copyClick(order)}
                                >
                                   Copy
                                </button>
                             </>
                          ) : null}
                       </td>
                    </tr>
                 ))
               : null}
         </tbody>
      </Table>
   );
};

export default OrdersTable;
