import { BASE_URL_ADMIN } from "../api/baseUrl";
import axios from "axios";

const apiLogin = async (user) => {
   try {
      const result = await axios.post(BASE_URL_ADMIN + "/auth", user);
      return result.data;
   } catch (error) {
      return error.response.data;
   }
};

const apiForgotPassword = async (email) => {
   try {
      const result = await axios.post(
         BASE_URL_ADMIN + "/auth/forgot-password",
         email
      );

      return result.data;
   } catch (error) {
      return error.response.data;
   }
};

const apiResetPassword = async (queryParams, password) => {
   try {
      const result = await axios.post(
         BASE_URL_ADMIN + "/auth/reset-password" + queryParams,
         password
      );
      return result.data;
   } catch (error) {
      return error.response.data;
   }
};

const authApi = {
   apiLogin,
   apiForgotPassword,
   apiResetPassword,
};
export default authApi;
