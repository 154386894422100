import axios from "axios";
import { BASE_URL } from "./baseUrl";
import storage from "../auth/storage";

const fetchStockMovements = async (year) => {
   try {
      const result = await axios.get(BASE_URL + "/stock?year=" + year, {
         headers: {
            "x-auth-token": storage.getToken(),
            tenant: storage.getTenant(),
         },
      });
      return result;
   } catch (error) {
      console.log(error.message);
   }
};

const getStockMovementsPerMonth = async (year, type) => {
   try {
      const result = await axios.get(
         `${BASE_URL}/stock/reports?year=${year}&type=${type}`,
         {
            headers: {
               "x-auth-token": storage.getToken(),
               tenant: storage.getTenant(),
            },
         }
      );
      return result;
   } catch (error) {
      console.log(error.message);
   }
};

const stockApi = {
   fetchStockMovements,
   getStockMovementsPerMonth,
};

export default stockApi;
