import React from "react";
import { Field, useFormikContext, ErrorMessage } from "formik";
import Form from "react-bootstrap/Form";

const AppInputField = ({ label, name, ...otherProps }) => {
   const { values, touched } = useFormikContext();
   return (
      <Form.Group>
         <Form.Label htmlFor={name}>{label}</Form.Label>
         <Field
            id={name}
            name={name}
            {...otherProps}
            className='form-control form-control-sm'
            value={values[name]}
         />

         <span className='text-danger'>
            {touched[name] ? <ErrorMessage name={name} /> : null}
         </span>
      </Form.Group>
   );
};

export default AppInputField;
