import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Card from "react-bootstrap/Card";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Modal from "react-bootstrap/Modal";

import ShoeForm from "./forms/ShoeForm";
import AdjustStockModal from "./modals/AdjustStockModal";
import {
  addShoe,
  updateShoe,
  selectAllContacts,
} from "../redux/contactReducer";
import { selectAllSuppliers } from "../redux/supplierReducer";

const ShoesComponent = ({ contact, setSelectedContact }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showStockModal, setShowStockModal] = useState(false);
  const [activeKey, setActiveKey] = useState();
  const [selectedShoe, setSelectedShoe] = useState();
  const [edit, setEdit] = useState(true);
  const [addUpdateStatus, setAddUpdateStatus] = useState("idle");
  const suppliers = useSelector(selectAllSuppliers);
  const contacts = useSelector(selectAllContacts);

  const handleSubmit = async (values, id) => {
    // Update or save new shoe to database
    if (edit && addUpdateStatus === "idle") {
      try {
        setAddUpdateStatus("pending");
        await dispatch(updateShoe({ id, values })).unwrap();
        toast.success("Shoe updated!");
      } catch (error) {
        toast.error(`Failed to update shoe: ${error.error}`);
      } finally {
        setAddUpdateStatus("idle");
      }
    } else {
      try {
        setAddUpdateStatus("pending");
        await dispatch(addShoe({ id: contact._id, values })).unwrap();
        handleClose();

        toast.success("Shoe added to contact");
      } catch (error) {
        toast.error(`Failed to add shoe: ${error.error}`);
      } finally {
        setAddUpdateStatus("idle");
      }
    }
  };

  const handleShow = () => {
    setEdit(false);
    setShowModal(true);
  };
  const handleClose = () => {
    setEdit(true);
    setShowModal(false);
  };
  const handleStockShow = () => {
    setShowStockModal(true);
  };
  const handleStockClose = () => {
    setShowStockModal(false);
  };
  useEffect(() => {
    setActiveKey(contact?.shoes[0]?._id);
  }, [contact]);

  useEffect(() => {
    const currentContact = contacts.find((c) => c._id === contact._id);
    setSelectedContact(currentContact);
  }, [contacts]);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between ">
          <h3>Shoes</h3>

          <button className="btn btn-outline-primary " onClick={handleShow}>
            Add shoe
          </button>
        </Card.Header>
        <Card.Body>
          {contact?.shoes?.length < 1 ? (
            <h5>No shoes. Press Add shoe to start adding shoes</h5>
          ) : null}
          <Tabs
            defaultActiveKey={activeKey}
            id="uncontrolled-tab"
            className="mb-3"
          >
            {contact.shoes?.map((shoe) => (
              <Tab
                eventKey={shoe._id}
                title={`${shoe.supplier.supplierName} - ${
                  shoe.maker || shoe.code
                }`}
                tabClassName={shoe.status === "unused" ? "text-danger" : ""}
                key={shoe._id}
                onClick={() => setSelectedShoe(shoe)}
              >
                <ShoeForm
                  onSubmit={handleSubmit}
                  shoe={shoe}
                  suppliers={suppliers}
                  status={addUpdateStatus}
                  onClick={handleStockShow}
                />
              </Tab>
            ))}
          </Tabs>
        </Card.Body>
      </Card>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Create new shoe for user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShoeForm
            onSubmit={handleSubmit}
            shoe={null}
            suppliers={suppliers}
            status={addUpdateStatus}
          />
          <div className="row mb-3 justify-content-center px-5">
            <button className="btn btn-danger" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <AdjustStockModal
        onStockClose={handleStockClose}
        showStockModal={showStockModal}
        shoe={selectedShoe}
        contactId={contact._id}
      />
    </>
  );
};

export default ShoesComponent;
