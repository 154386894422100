import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Table from "react-bootstrap/Table";

import { fetchAllContacts } from "../redux/contactReducer";

import helpers from "../utilities/helperFunctions";
import StockMovements from "./reports/StockMovements";
import AdjustStockModal from "./modals/AdjustStockModal";

const Messages = ({ contacts }) => {
   const [showStockModal, setShowStockModal] = useState(false);
   const [selectedShoe, setSelectedShoe] = useState();
   const [selectedContact, setSelectedContact] = useState();
   const dispatch = useDispatch();
   const [report, setReport] = useState("stock");
   const [showInactive, setShowInactive] = useState(false);

   const handleStockClose = () => {
      setShowStockModal(false);
   };

   const allContacts = helpers.sortItems([...contacts], "firstName");
   const filteredContacts = allContacts?.filter(contact => {
      if (showInactive) return contact;
      if (contact.active) return contact;
   });

   const Stock = () => {
      return (
         <>
            <div className='form-check form-switch'>
               <input
                  className='form-check-input'
                  type='checkbox'
                  id='active'
                  checked={showInactive}
                  onChange={() => setShowInactive(!showInactive)}
               />
               <label className='form-check-label' htmlFor='active'>
                  Show&nbsp;inactive
               </label>
            </div>
            <Table striped hover>
               <thead>
                  <tr>
                     <th>Dancer</th>
                     <th>Shoe</th>
                     <th>On stock</th>
                     <th>Adjust stock</th>
                  </tr>
               </thead>
               <tbody>
                  {filteredContacts.map(contact => {
                     return contact.shoes.map(shoe => (
                        <tr key={shoe._id}>
                           <td>{`${contact.firstName} ${contact.lastName}`}</td>
                           <td>{` ${shoe.maker} ${shoe.code} ${shoe.size} ${shoe.width}`}</td>
                           <td>{shoe.amountOnStock}</td>
                           <td>
                              <button
                                 className='btn btn-outline-primary'
                                 onClick={() => {
                                    setSelectedContact(contact._id);
                                    setSelectedShoe(shoe);
                                    setShowStockModal(true);
                                 }}
                              >
                                 Adjust stock
                              </button>
                           </td>
                        </tr>
                     ));
                  })}
               </tbody>
            </Table>
            <AdjustStockModal
               onStockClose={handleStockClose}
               showStockModal={showStockModal}
               shoe={selectedShoe}
               contactId={selectedContact}
            />
         </>
      );
   };

   const handleClickStock = async () => {
      dispatch(fetchAllContacts());
      setReport("stock");
   };

   const handleClickUsage = async () => {
      setReport("usage");
   };

   return (
      <div className='container-fluid'>
         <div className='row'>
            <div className='d-flex'>
               <button
                  className='btn btn-outline-primary me-3'
                  onClick={handleClickStock}
               >
                  Stock
               </button>

               <button
                  className='btn btn-outline-primary'
                  onClick={handleClickUsage}
               >
                  Usage
               </button>
            </div>

            <hr className='my-3' />
         </div>
         <div className='row'>
            <div className='report-container'>
               {report === "stock" ? <Stock /> : null}
               {report === "usage" ? <StockMovements /> : null}
            </div>
         </div>
      </div>
   );
};

export default Messages;
