import React from "react";
import { useFormikContext, useField, ErrorMessage } from "formik";
import DatePicker, { registerLocale } from "react-datepicker";
import nb from "date-fns/locale/nb";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerField = ({ ...props }) => {
   const { setFieldValue, touched } = useFormikContext();
   const [field] = useField(props);

   registerLocale("nb", nb);

   return (
      <>
         <DatePicker
            {...field}
            {...props}
            selected={(field.value && new Date(field.value)) || null}
            onChange={(val) => {
               let date = new Date(val);
               date.setHours(0 + 12);
               console.log(date);
               setFieldValue(field.name, date);
            }}
            locale='nb'
            dateFormat='dd-MM-y'
            minDate={new Date()}
         />
         <span className='text-danger'>
            {touched[field.name] ? <ErrorMessage name={field.name} /> : null}
         </span>
      </>
   );
};

export default DatePickerField;
