import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";
import { useSelector } from "react-redux";

import { selectAllSuppliers } from "../redux/supplierReducer";
import { selectAllContacts } from "../redux/contactReducer";
import { selectAllOrders } from "../redux/ordersReducer";

import { DashboardCard } from "../components/card/DashboardCard";
import OrdersTable from "../components/tables/OrdersTable";
import Spinner from "../components/Spinner";

import helper from "../utilities/helperFunctions";
import StockChartYear from "../components/charts/StockChartYear";
import CostChartYear from "../components/charts/CostChartYear";

const Dashboard = ({ status }) => {
   const suppliers = useSelector(selectAllSuppliers);
   const contacts = useSelector(selectAllContacts);
   const orders = useSelector(selectAllOrders);

   const filteredOrders = orders.slice(0, 5);

   return (
      <>
         <main className='container mt-5 '>
            {status !== "succeeded" ? <Spinner /> : null}
            <div className='row d-flex mb-5'>
               <div className='col-12 col-md-4 col-lg-3 col-xl-2 mb-2'>
                  <DashboardCard
                     heading='Total'
                     total={helper.totalOnStock(contacts)}
                     bg='success'
                  />
               </div>
               {suppliers?.map(supplier => {
                  const total = helper.totalOnStock(contacts, supplier._id);
                  if (total)
                     return (
                        <div
                           className='col-12 col-md-4 col-lg-3 col-xl-2 mb-2'
                           key={supplier._id}
                        >
                           <DashboardCard
                              heading={supplier.supplierName}
                              total={total}
                              bg='success'
                           />
                        </div>
                     );
                  else return null;
               })}
            </div>
            <div className='row g-5 '>
               <div
                  className='col-12 col-xl-6 mb-4 p-2 '
                  style={{ minHeight: "300px" }}
               >
                  <div className='p-2 border rounded shadow-sm'>
                     <StockChartYear />
                  </div>
               </div>

               <div
                  className='col-12 col-xl-6 mb-4 p-2 '
                  style={{ minHeight: "300px" }}
               >
                  <div className='p-2 border rounded shadow-sm'>
                     <CostChartYear />
                  </div>
               </div>
            </div>
            <div className='row d-flex'>
               <div className='col-12 col-xl-6 mb-4 '>
                  <Card className='shadow'>
                     <Card.Header>
                        <h2>Recent orders</h2>
                     </Card.Header>
                     <Card.Body>
                        <OrdersTable orders={filteredOrders} />
                     </Card.Body>
                  </Card>
               </div>
            </div>
         </main>
      </>
   );
};

export default Dashboard;
