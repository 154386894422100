import React from "react";

const Spinner = ({ color = "text-dark" }) => {
   return (
      <div className='d-flex justify-content-center  bg-transparent'>
         <div className={`spinner-border ${color}`} role='status'>
            <span className='visually-hidden'>Loading...</span>
         </div>
      </div>
   );
};

export default Spinner;
