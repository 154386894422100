import React from "react";
import Menubar from "../components/menubar/Menubar";
import Container from "react-bootstrap/Container";
import FeatureCard from "../components/card/FeatureCard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ContactEmailForm from "../components/forms/ContactMailForm";

import images from "../constants/images";

const Shoestocked = () => {
   return (
      <>
         <Menubar />
         <Container
            fluid
            className='bg-primary text-light justify-content-center pt-4 pt-lg-5 pb-5'
         >
            <Container className=' justify-content-center'>
               <h1 className=' text-center'>
                  Keep track of your dancers shoes
               </h1>
               <p className='text-center text-white my-4'>
                  Quickly get an overview of ballet shoes on stock as well as
                  keeping a track on orders and usage
               </p>
               <div className='d-flex  justify-content-center'>
                  <a href='/contact'>
                     <Button variant='outline-light'>Contact</Button>
                  </a>
               </div>
            </Container>
            <div></div>
         </Container>
         <div className='section-container'>
            <Container>
               <Row className='justify-content-center'>
                  <Col xs={12} md={6} lg='auto' className='pb-4'>
                     <div className='d-flex justify-content-center h-100'>
                        <FeatureCard
                           title='Shoes'
                           subTitle='Keep track of all your dancers pointe shoes and ballet shoes'
                           text={
                              <>
                                 <ul>
                                    <li>
                                       Deatiled inventory of each shoe for each
                                       dancer
                                    </li>
                                    <li>
                                       All details for shoe including size,
                                       width, vamp, and any personal adjustments
                                    </li>
                                    <li>Stock movement overview of usage</li>
                                 </ul>
                              </>
                           }
                        />
                     </div>
                  </Col>
                  <Col xs={12} md={6} lg='auto' className='pb-4'>
                     <div className='d-flex justify-content-center h-100'>
                        <FeatureCard
                           title='Orders'
                           subTitle='Keep track of all orders sent to suppliers'
                           text={
                              <>
                                 <ul>
                                    <li>Overview of current and past orders</li>
                                    <li>
                                       Possibility to copy or modify an order
                                       for next time the order is set
                                    </li>
                                    <li>
                                       Recieve full or partial orders and add
                                       directly to stock
                                    </li>
                                    <li>
                                       Overview of which shoes have been
                                       received or not in an order
                                    </li>
                                 </ul>
                              </>
                           }
                        />
                     </div>
                  </Col>
                  <Col xs={12} md={6} lg='auto' className='pb-4'>
                     <div className='d-flex justify-content-center h-100'>
                        <FeatureCard
                           title='Contacts'
                           subTitle='Have all your contacts, dancers and suppliers in one place'
                           text={
                              <>
                                 <ul>
                                    <li>
                                       Easy access to dancers and supplier
                                       information
                                    </li>
                                    <li>
                                       Each dancer can have multiple shoes
                                       assigned to them
                                    </li>
                                    <li>
                                       Dancers can use an app to remove shoes
                                       from stock to always shoe the updated
                                       stock amount
                                    </li>
                                    <li>
                                       Overview of which shoes have been
                                       received or not in an order
                                    </li>
                                 </ul>
                              </>
                           }
                        />
                     </div>
                  </Col>
               </Row>
            </Container>
         </div>
         <div className='section-container  '>
            <Container>
               <Row>
                  <Col>
                     <header className='d-flex justify-content-center mb-5'>
                        <h3>
                           Mobile app for users to track their stock and pending
                           orders
                        </h3>
                     </header>
                  </Col>
               </Row>
               <Row>
                  <Col xs={12} className='justify-content-center'>
                     <section className='d-flex flex-column-reverse flex-lg-row justify-content-center gap-4'>
                        <div className='d-flex flex-column flex-lg-row justify-content-center gap-4'>
                           <div className='w-25 shadow'>
                              <img
                                 src={images.mobile1}
                                 class='img-fluid rounded-top'
                                 alt='mobile'
                              />
                           </div>
                           <div className='w-25 shadow'>
                              <img
                                 src={images.mobile2}
                                 class='img-fluid rounded-top'
                                 alt='mobile'
                              />
                           </div>
                           <div className='w-25 shadow'>
                              <img
                                 src={images.mobile3}
                                 class='img-fluid rounded-top'
                                 alt='mobile'
                              />
                           </div>
                        </div>
                     </section>
                  </Col>
               </Row>
            </Container>
         </div>
         <div className='section-container bg-primary text-white  '>
            <Container>
               <Row>
                  <Col xs={12} className='justify-content-center'>
                     <section className='d-flex flex-column flex-lg-row justify-content-center gap-4'>
                        <div className='w-100 text-center p-2 p-lg-5'>
                           <h3>Contact us for more information</h3>
                           <p>We will get back to you as soon as possible</p>
                        </div>
                        <div className='w-100 p-2 pt-4 p-lg-5'>
                           <ContactEmailForm />
                        </div>
                     </section>
                  </Col>
                  <Col></Col>
               </Row>
            </Container>
         </div>
      </>
   );
};

export default Shoestocked;
