import React from "react";

import { Form } from "formik";
import * as yup from "yup";
import AppForm from "./AppForm";
import AppInputField from "./AppInputField";
import AppSelectField from "./AppSelectField";

const schema = yup.object().shape({
   contact: yup
      .string()
      .required()
      .min(2)
      .max(25, "Max 25 characters")
      .label("Contact"),
   email: yup.string().email().required().max(50).label("Email"),
   phone: yup.number().notRequired().label("Phone"),
   supplierName: yup.string().required().label("Supplier name"),
});

const SupplierForm = ({ supplier = null, contacts, onSubmit, edit }) => {
   const initialValues = {
      supplierName: supplier?.supplierName || "",
      contact: supplier?.contact._id || "",
      email: supplier?.email || "",
      phone: supplier?.phone || "",
   };

   const filteredContacts = contacts.filter((c) => c.type === "supplier");
   const options = filteredContacts.map((contact) => {
      return {
         value: contact._id,
         name: `${contact.firstName} ${contact.lastName}`,
      };
   });

   return (
      <AppForm
         initialValues={initialValues}
         onSubmit={(values) => onSubmit(values, supplier?._id)}
         validationSchema={schema}
         enableReinitialize={true}
         key={supplier?._id || "newSupplier"}
      >
         <Form>
            <div className='row mb-3'>
               <div className='col-12 '>
                  <AppInputField
                     label='Supplier name'
                     name='supplierName'
                     placeholder='Supplier name'
                  />
               </div>
            </div>
            <div className='row mb-3'>
               <div className='col-12 '>
                  <AppInputField
                     label='Email'
                     name='email'
                     placeholder='Email'
                  />
               </div>
            </div>
            <div className='row mb-3'>
               <div className='col-12 '>
                  <AppInputField
                     label='Phone'
                     name='phone'
                     placeholder='Phone'
                  />
               </div>
            </div>
            <div className='row mb-3'>
               <div className='col-12 '>
                  <AppSelectField
                     label='Contact'
                     name='contact'
                     placeholder='Contact'
                     options={options}
                  />
               </div>
            </div>

            <div className='row mb-3 justify-content-center px-5'>
               <button type='submit' className='btn btn-primary mt-3'>
                  {edit ? "Update supplier" : "Create supplier"}
               </button>
            </div>
         </Form>
      </AppForm>
   );
};

export default SupplierForm;
