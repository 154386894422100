import { BASE_URL } from "./baseUrl";
import axios from "axios";
import storage from "../auth/storage";

export const addShoeDb = async (contactId, shoe) => {
   return await axios.post(BASE_URL + "/shoes/" + contactId, shoe, {
      headers: {
         "x-auth-token": storage.getToken(),
         tenant: storage.getTenant(),
      },
   });
};

export const updateShoeDb = async (shoeId, shoe) => {
   return await axios.put(BASE_URL + "/shoes/" + shoeId, shoe, {
      headers: {
         "x-auth-token": storage.getToken(),
         tenant: storage.getTenant(),
      },
   });
};

export const addShoeStock = async (shoeId, values, contactId) => {
   return await axios.put(
      BASE_URL + "/shoes/add-stock/" + shoeId,
      { stockAdd: values.amount, contact: contactId },
      {
         headers: {
            "x-auth-token": storage.getToken(),
            tenant: storage.getTenant(),
         },
      }
   );
};
export const reduceShoeStock = async (shoeId, values, contactId) => {
   return await axios.put(
      BASE_URL + "/shoes/stock/" + shoeId,
      { stockReduction: values.amount, contact: contactId },
      {
         headers: {
            "x-auth-token": storage.getToken(),
            tenant: storage.getTenant(),
         },
      }
   );
};
